<template>
  <div class="single-poi" @click="showSinglePOI" @mouseover="mouseover" @mouseleave="mouseleave">
    <div v-if="item.cover || item.parent.image" class="single-poi-img">
      <img class="cover-img" :src="hostAddress + (item.cover || item.parent.image)" alt="" :style="getCoverBackground(item)"/>
      <div class="is-opened-info" v-if="item.opening_hours" :class="{opened: isOpened}">
        <i class="material-icons-round md-12 white">schedule</i>
        {{isOpened ? $t("poiDetails.opened") : $t("poiDetails.closed")}}
      </div>
    </div>
    <div v-else class="single-poi-img"><img class="cover-img" src="/images/placeholder.png" alt="" /></div>
    <div class="single-poi-data">
      <p class="single-poi-name">{{ item.name }}</p>
      <p class="single-poi-address" v-if="item.address">
        {{ item.address[0] }}
      </p>
      <p class="single-poi-address" v-if="item.address">
        {{ item.address[1] }}
      </p>
      <div class="single-poi-category">
        <img class="single-poi-category-logo" :src="hostAddress + item.parent.logo" v-if="item.parent.logo" />
        <div class="single-poi-category-name">{{ item.parent.name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import CategoryService from "@/services/category";
import M2wApi from "@/services/m2w_api";

export default {
  name: "PoiTocCard",
  data() {
    return {
      hostAddress: M2wApi.host_address
    };
  },
  props: {
    item: Object
  },
  computed: {
    isOpened: function() {
      return CategoryService.checkIfOpened(this.item, true);
    }
  },

  methods: {
    async showSinglePOI() {
      await this.$router.push({name: "poiDetails", params: {id: this.item.id}, query: {parent: this.item.parent.id}});
    },
    mouseover() {
      //basemap.setCircleMarkerStyle(this.item);
    },
    mouseleave() {
      //basemap.deleteOverlayMarker(this.item);
    },
    getCoverBackground() {
      if (this.item.parent.style && this.item.parent.style.fill_color) {
        return {'background-color': this.item.parent.style.fill_color};
      }
      return '';
    }
  }
};
</script>
