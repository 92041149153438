<template>
  <div class="wfs">
    <div class="intro" v-if="!feature">
      <div id="category-header">
        <button id="arrow" @click="$emit('return')">
          <i v-if="!$root.$data.embedded" class="material-icons-round">arrow_back_ios</i>
          {{ category.name }}
        </button>
      </div>
      <p class="description">{{ $t("wfs.intro_description") }}</p>
      <div class="error" v-if="error">
        <i class="close-btn material-icons-round" @click="error=null">cancel</i>
        {{error}}
      </div>
    </div>
    <div v-if="feature">
      <div class="close-button" @click="clear()">
        <img src="../../assets/icons/cancel-24px.png" alt="close" />
      </div>
      <div id="wfs-data" v-html="feature.content"></div>
    </div>
  </div>
</template>

<script>
import M2wApi from "@/services/m2w_api";
import {basemap} from "@/services/map/basemap";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import ZIndex from "@/services/zindex";

export default {
  name: "WfsFolder",
  props: ["category"],
  data() {
    return {
      feature: null,
      layer: null,
      error: null
    };
  },

  mounted() {
    basemap.map.on("singleclick", this.queryFeature);
  },

  unmounted() {
    basemap.map.un("singleclick", this.queryFeature);
    this.clear();
  },

  methods: {
    clear() {
      if(this.layer) {
        basemap.map.removeLayer(this.layer);
      }
      this.layer = null;
      this.error = null;
      this.feature = null;
    },

    async queryFeature(event) {
      this.clear();
      this.$Progress.start();
      const radius = basemap.map.getView().getResolution() * 10;
      console.log(radius);
      let extent = [event.coordinate[0]-radius, event.coordinate[1]-radius, event.coordinate[0]+radius, event.coordinate[1]+radius];
      try {
        this.feature = await M2wApi.get_wfs_feature(this.category.id, extent, this.$i18n.locale);

        let geom = this.feature.geometry;
        let ft = null;
        switch (geom.type) {
          case "Point":
            ft = this.$layers.createPointFeature(geom.coordinates, this.category.marker);
            break;
          case "Polygon":
          case "MultiPolygon":
            ft = this.$layers.createPolygonFeature(geom, this.category.style);
            break;
        }
        let source = new VectorSource({
          features: [ft]
        });

        this.layer = new VectorLayer({
          source: source
        });
        this.layer.setZIndex(ZIndex.Marker);
        basemap.map.addLayer(this.layer);
      } catch(error) {
        this.feature = null;
        if (error.message === "404") {
          this.error = this.$t("wfs.no_elem_found")
        } else{
          this.error = this.$t("wfs.unknown_error") + error.message;
        }
      }
      this.$Progress.finish();
    }
  }
};
</script>