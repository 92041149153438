export default class CategoryService {
  static getClusterColor() {
    return 'rgb(220, 0, 0, 0.6)';
  }

  static checkIfOpened(poi, singleDay = false) {
    if (!poi.opening_hours) return null;

    let openingHours = poi.opening_hours;
    let date = new Date();
    let totalMinutes = date.getHours() * 60 + date.getMinutes();

    let todaysHours = singleDay ? openingHours : openingHours[openingHours.weekday];
    if (openingHours.is_holiday) todaysHours = openingHours["Holiday"];

    if (todaysHours.length === 0) return false;

    let parseTime = function(str) {
      let chunks = str.split(":");
      return parseInt(chunks[0], 10) * 60 + parseInt(chunks[0], 10);
    };

    for (let span of todaysHours) {
      let begin = parseTime(span[0]);
      let end = parseTime(span[1]);
      // wrap around to the next day
      if (end < begin) end += 24 * 60;

      if (totalMinutes >= begin && totalMinutes <= end) return true;
    }
    return false;
  }
}
