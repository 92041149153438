<template>
  <div class="layers-wrapper">
    <div class="lr-space" v-if="sf_basemap">
      <h3>{{ $t("settings.transparency") }}</h3>
      <vue-slider v-model="sfLayerOpacity" />
    </div>
    <h3 class="lr-space">{{ $t("settings.backdrop") }}</h3>
    <div class="layers">
      <button
        :class="{active: activeLayer === layer.id}"
        v-for="layer in allLayers"
        :key="layer.id"
        @click="changeLayer(layer.id)"
      >
        <div class="button-inner">
          <img :src="hostAddress + layer.image_url" :alt="layer.name" />
          <div>
            <div class="layer-name">{{ layer.name }}</div>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>
<script>
import {basemap} from "@/services/map/basemap";
import M2wApi from "@/services/m2w_api";
import Vue from "vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  name: "LayersControl",
  components: {
    VueSlider
  },
  mounted() {
    if (this.$route.query["sflo"] && !isNaN(this.$route.query["sflo"])) {
      let parsed = parseInt(this.$route.query["sflo"], 10);
      if (parsed >= 0 && parsed <= 100) {
        this.sfLayerOpacity = parsed;
      }
    }
  },

  data() {
    return {
      allLayers: basemap.allLayers,
      hostAddress: M2wApi.host_address,
      activeLayer: basemap.activeLayer,
      sfLayerOpacity: 100,
      sf_basemap: Vue.config.project.sf_basemap
    };
  },

  methods: {
    async changeLayer(layerId) {
      await basemap.changeBasemap(layerId);
      this.activeLayer = layerId;
      this.$router.replace({query: {...this.$route.query, lid: layerId}});
    }
  },
  watch: {
    sfLayerOpacity: function(val) {
      this.sf_basemap.layer.setOpacity(val / 100);
      this.$router.replace({query: {...this.$route.query, sflo: val}});
    }
  }
};
</script>
