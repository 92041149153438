<template>
  <div class="settings-control">
    <div class="mobile-only">
      <LayerControl />
    </div>
    <div class="language lr-space">
      <h3>{{ $t("settings.language.title") }}</h3>
      <select v-model="$i18n.locale" class="lng select">
        <option v-for="lang in languages" :key="lang.abbreviation" :value="lang.abbreviation">{{
          $t("settings.language." + lang.name)
        }}</option>
      </select>
    </div>
    <div class="language lr-space">
      <h3>{{ $t("settings.clustering.title") }}</h3>
      <select v-model="clustering" class="lng select">
        <option value="never">{{ $t("settings.clustering.never" ) }}</option>
        <option value="always">{{ $t("settings.clustering.always" ) }}</option>
        <option value="dynamic">{{ $t("settings.clustering.dynamic" ) }}</option>
      </select>
    </div>
    <div class="functions lr-space">
      <h3>{{ $t("settings.functions.title") }}</h3>
      <div class="icons">
        <button @click="print()">
          <span class="material-icons-round">print</span> {{ $t("settings.functions.print") }}
        </button>
        <button @click="measure()">
          <span class="material-icons-round">square_foot</span> {{ $t("settings.functions.measure") }}
        </button>
      </div>

      <a class="embedLinks" @click="showEmbedLinks = !showEmbedLinks">
        {{ $t("settings.embedLinks" )}}<span class="material-icons-round">{{showEmbedLinks ? "keyboard_arrow_up" : "keyboard_arrow_down"}}</span>
      </a>
      <CopyableInput v-if="showEmbedLinks" :input-data="embedLink" />
      <CopyableInput v-if="showEmbedLinks" :input-data="iframeLink" />
    </div>
    <div class="mobile-only lr-space">
      <ImprintLinks />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import LayerControl from "./LayersControl";
import EmbedHelper from "@/services/embedHelper";
import ImprintLinks from "@/components/page_elements/ImprintLinks";
import CopyableInput from "@/components/page_elements/CopyableInput";

export default {
  name: "SettingsControl",
  components: {CopyableInput, ImprintLinks, LayerControl},
  data() {
    return {
      languages: Vue.config.project.languages,
      date: new Date(),
      embedLink: EmbedHelper.getEmbedUri(),
      iframeLink: EmbedHelper.getIframeCode(),
      showEmbedLinks: false,
      clustering: this.$cookie.get("item_clustering") || "dynamic"
    };
  },
  methods: {
    print() {
      this.$root.$data.printMode = !this.$root.$data.printMode;
      this.$emit("closeFrame");
    },
    async measure() {
      await this.$router.push({name: "measure", query: this.$route.query});
      this.$emit("closeFrame");
    }
  },

  watch: {
    "$i18n.locale": function(lang) {
      this.$cookie.set("locale", lang, 30);
    },
    $route: function() {
      this.embedLink = EmbedHelper.getEmbedUri();
      this.iframeLink = EmbedHelper.getIframeCode();
    },
    clustering: function(val) {
      this.$cookie.set("item_clustering", val, 30);
      Vue.config.item_clustering = val;
    }
  }
};
</script>
