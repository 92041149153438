<template>
  <div>
    <div id="setting-buttons">
      <button
        v-if="!$root.$data.embedded"
        class="material-icons-round md-20"
        :class="{active: currentComponent === 'Settings'}"
        :title="$t('SettingsControl')"
        @click="toggleFrame('Settings')"
      >settings</button>
      <button
        v-if="!$root.$data.embedded"
        class="material-icons-round md-20 desktop-only"
        :class="{active: currentComponent === 'Layers'}"
        :title="$t('LayersControl')"
        @click="toggleFrame('Layers')"
      >layers</button>
      <button
          v-if="!$root.$data.embedded"
          class="material-icons-round md-20"
          :class="{active: geolocationEnabled}"
          :title="$t('buttons.gps')"
          @click="geolocationEnabled = !geolocationEnabled"
      >gps_fixed</button>
      <button
        v-if="$root.$data.embedded"
        class="material-icons-round md-20"
        :title="$t('buttons.popout')"
        @click="popout()"
      >launch</button>
      <FullScreenControl v-if="!$root.$data.embedded" />
    </div>
    <ControlPopup :currentName="currentComponent" @closeFrame="closeFrame" />
  </div>
</template>
<script>
import FullScreenControl from "./FullScreenControl";
import ControlPopup from "./ControlPopup";
import EmbedHelper from "../../services/embedHelper";
import Geolocation from 'ol/Geolocation';
import {basemap} from "../../services/map/basemap";
import Feature from "ol/Feature";
import Style from "ol/style/Style";
import CircleStyle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Point from "ol/geom/Point";
import {Vector as VectorSource} from "ol/source";
import {Vector as VectorLayer} from "ol/layer";
import ZIndex from "../../services/zindex";
import Stroke from "ol/style/Stroke";
import {easeOut} from 'ol/easing';

export default {
  components: {ControlPopup, FullScreenControl},
  data() {
    return {
      currentComponent: null,
      components: ["Settings", "Layers"],
      geolocationEnabled: false,
      geolocation: null
    };
  },
  watch: {
    geolocationEnabled: function(enabled) {
      if (this.geolocation === null) {
        this.setupGeolocation();
      }

      this.geolocation.gl.setTracking(enabled);

      if (enabled) {
        basemap.map.addLayer(this.geolocation.layer);
      } else if (this.geolocation.layer !== null) {
        basemap.map.removeLayer(this.geolocation.layer);
      }
    }
  },

  methods: {
    toggleFrame(component) {
      if (this.currentComponent === component) this.currentComponent = null;
      else this.currentComponent = component;
    },
    closeFrame() {
      this.currentComponent = null;
    },
    setupGeolocation() {
      let map = basemap.map;
      let geolocation = new Geolocation({
        // enableHighAccuracy must be set to true to have the heading value.
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: map.getView().getProjection(),
      });

      let accuracy = new Feature();
      let position = new Feature();
      position.setStyle(
          new Style({
            image: new CircleStyle({
              radius: 6,
              fill: new Fill({
                color: '#3399CC',
              }),
              stroke: new Stroke({
                color: '#fff',
                width: 2,
              }),
            }),
          })
      );

      geolocation.on('change:accuracyGeometry', function () {
        accuracy.setGeometry(geolocation.getAccuracyGeometry());
      });

      geolocation.on('change:position', function () {
        const coordinates = geolocation.getPosition();
        position.setGeometry(coordinates ? new Point(coordinates) : null);
      });
      geolocation.once('change:position', function() {
        const coordinates = geolocation.getPosition();
        basemap.map.getView().animate({
          center: coordinates,
          duration: 500,
          easing: easeOut
        });
      });

      geolocation.on('error', function (error) {
        console.log(error.message);
        this.geolocationEnabled = false;
      });

      let vectorSource = new VectorSource({
        features: [accuracy, position]
      });
      let layer = new VectorLayer({
        source: vectorSource,
        crossOrigin: "anonymous",
        updateWhileAnimating: true,
        updateWhileInteracting: true
      });
      layer.setZIndex(ZIndex.Marker);
      this.geolocation = {
        gl: geolocation,
        layer: layer
      }
    },
    popout() {
      let target = EmbedHelper.getStandalone();
      let win = window.open(target, "_blank");
      win.focus();
    }
  }
};
</script>
