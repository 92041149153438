import Vue from "vue";
import {Map, View} from "ol";
import TileLayer from "ol/layer/Tile";
import ImageLayer from "ol/layer/Image";
import {ImageWMS} from "ol/source";
import WMSCapabilities from "ol/format/WMSCapabilities";
import WMTSCapabilities from "ol/format/WMTSCapabilities";
import {optionsFromCapabilities} from "ol/source/WMTS";
import XYZ from "ol/source/XYZ";
import GoogleLayer from "olgm/layer/Google.js";
import OLGoogleMaps from "olgm/OLGoogleMaps.js";
import Feature from "ol/Feature";
import {Circle as CircleString, LineString, MultiLineString, Point, Polygon} from "ol/geom";
import {Circle, Fill, Icon, Stroke, Style} from "ol/style";
import {OSM, Vector as VectorSource, WMTS} from "ol/source";
import {Vector as VectorLayer} from "ol/layer";
import M2wApi from "../m2w_api";
import {defaults, ScaleLine} from "ol/control";
import Draw, {createBox} from "ol/interaction/Draw";
import Overlay from "ol/Overlay";
import {getArea, getLength} from "ol/sphere";
import {unByKey} from "ol/Observable";
import {createEmpty, extend, isEmpty} from "ol/extent";
import GeoJSON from "ol/format/GeoJSON";
import {googleMaps} from "@/services/map/googleMaps";
import Attribution from "ol/control/Attribution";
import ZIndex from "@/services/zindex";
import {fromCircle} from "ol/geom/Polygon";
import {applyStyle} from 'ol-mapbox-style';
import VectorTileLayer from 'ol/layer/VectorTile.js'

export default class Basemap {
  constructor() {
    this.parser = new WMTSCapabilities();
    this.allLayers = [];
    this.googleLayers = [];
    this.activeLayer = 0;
    this.host_address = M2wApi.host_address;
    this.vectorArray = [];
  }

  async initMap(layer_id) {
    this.allLayers.push(...Vue.config.project.base_layers);
    if (layer_id && !isNaN(layer_id)) {
      this.activeLayer = this.allLayers.find(x => x.id === parseInt(layer_id, 10)).id;
    } else {
      this.activeLayer = Vue.config.project.default_base_layer;
    }

    this.googleLayers = this.allLayers.filter(layer => layer.name.includes("Google"));
    await this.setupMap(this.activeLayer, Vue.config.project.min_zoom, Vue.config.project.max_zoom);
    this.setupGlobalInteraction();
  }

  toRgba(color, transparency) {
    let opacity = Math.floor((1 - transparency / 100) * 255);
    let hex = ("00" + opacity.toString(16)).slice(-2);
    let shortHexRegex = /^#([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])$/;
    let match = color.match(shortHexRegex);
    if (match) {
      color = "#" + match[1] + match[1] + match[2] + match[2] + match[3] + match[3];
    }

    return color + hex;
  }

  createMarkerForCoordinates(coordinates) {
    let markerUrl = "static/uploads/markers/4735turquoise_pinpoint2.png";
    let marker = new Feature({
      geometry: new Point(coordinates)
    });
    let markerStyle = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        crossOrigin: "anonymous",
        src: this.host_address + markerUrl
      })
    });
    marker.setStyle(markerStyle);
    let vectorSource = new VectorSource({
      features: [marker]
    });
    let vectorLayer = new VectorLayer({
      source: vectorSource,
      crossOrigin: "anonymous",
      updateWhileAnimating: true,
      updateWhileInteracting: true
    });
    vectorLayer.setZIndex(ZIndex.Marker);

    this.map.addLayer(vectorLayer);
    return vectorLayer;
  }

  drawLine(geom, strokeOptions = {color: "rgba(255,0,0,1)", width: 3}) {
    let line = new Feature({
      geometry: new GeoJSON().readGeometry(geom)
    });

    return this.drawLineInternal(line, strokeOptions);
  }

  drawEpsg4326Line(geom, strokeOptions = {color: "rgb(255,0,0)", width: 3}) {
    geom = geom.map(c => googleMaps.transformFromLatLng(c));

    let line = new Feature({
      geometry: new MultiLineString([geom])
    });

    return this.drawLineInternal(line, strokeOptions);
  }

  updateStyle(layer, strokeOptions) {
    layer.setStyle(
      new Style({
        stroke: new Stroke(strokeOptions)
      })
    );
  }

  drawLineInternal(line, strokeOptions) {
    let source = new VectorSource({
      features: [line],
      crossOrigin: "anonymous"
    });
    let style = new Style({
      stroke: new Stroke(strokeOptions)
    });
    let vectorLayer = new VectorLayer({
      source: source,
      style: style,
      updateWhileAnimating: true,
      updateWhileInteracting: true
    });
    vectorLayer.setZIndex(ZIndex.Drawing);
    this.map.addLayer(vectorLayer);
    return vectorLayer;
  }

  async setupMap(layer_id, minZoom, maxZoom) {
    let layers = [];

    let scaleLine = new ScaleLine({target: "scale-bar"});
    let attributions = new Attribution({
      collapsible: false,
      collapsed: false,
      target: "imprint"
    });
    let controls = defaults({
      attribution: false
    });
    controls.extend([scaleLine, attributions]);

    this.map = new Map({
      target: "map",
      layers: layers,
      controls: controls,
      view: new View({
        center: Vue.config.project.center,
        zoom: 15,
        constrainResolution: true,
        minZoom: minZoom,
        maxZoom: maxZoom
      })
    });

    if (this.googleLayers.length > 0) {
      let olGM = new OLGoogleMaps({
        map: this.map,
        watch: {
          tile: false,
          vector: false
        }
      });
      olGM.activate();
    }

    this.setupSettingsButtons();
    await this.changeBasemap(layer_id, false);
  }

  setupSettingsButtons() {
    if (document.getElementsByClassName("ol-zoom")[0] && document.getElementById("setting-buttons")) {
      let buttonsFrame = document.getElementsByClassName("ol-zoom")[0];
      let layerButton = document.getElementById("setting-buttons");
      buttonsFrame.appendChild(layerButton);
      layerButton.style.display = "block";
    }
  }

  async changeBasemap(layer_id, override = true) {
    this.activeLayer = layer_id;
    const layer = this.allLayers.find(l => l.id === layer_id);

    let olLayer = null;
    try {
      if (layer.map_type === "osm") olLayer = this.getOsmLayer();
      else if (layer.map_type === "google") olLayer = this.getGoogleLayer(layer);
      else if (layer.map_type === "wmts") olLayer = await this.getWmtsLayer(layer);
      else if (layer.map_type === "wms") olLayer = await this.getWmsLayer(layer);
      else if (layer.map_type === "xyz") olLayer = await this.getXyzLayer(layer);
      else if (layer.map_type === "gl_style") {
        olLayer = new VectorTileLayer({declutter: true, className: "gl_style"});
        applyStyle(olLayer, layer.url);
      }

      const existingLayers = this.map.getLayers();
      if (override) existingLayers.setAt(0, olLayer);
      else existingLayers.insertAt(0, olLayer);
    } catch (e) {
      console.log(e);
    }
  }

  getOsmLayer() {
    return new TileLayer({source: new OSM({crossOrigin: "Anonymous"})});
  }

  getGoogleLayer(layer) {
    this.map.getView().setMinZoom(0);
    return new GoogleLayer({mapTypeId: layer.lyid});
  }

  async getWmtsLayer(layer) {
    const response = await fetch(layer.url);
    const text = await response.text();

    let result = this.parser.read(text);
    let options = optionsFromCapabilities(result, {
      layer: layer.lyid ?? layer.name,
      matrixSet: result.Contents.TileMatrixSet[0].Identifier
    });
    options.attributions = layer.attributions;
    options.crossOrigin = "Anonymous";
    return new TileLayer({
      source: new WMTS(options)
    });
  }

  async getWmsLayer(layer) {
    const response = await fetch(layer.url);
    const text = await response.text();
    const parserWMS = new WMSCapabilities();
    let result = parserWMS.read(text);
    let WMSurl = result.Service.OnlineResource;

    return new ImageLayer({
      source: new ImageWMS({
        url: WMSurl,
        params: {LAYERS: layer.lyid ?? layer.name}
      })
    });
  }

  async getXyzLayer(layer) {
    return new TileLayer({source: new XYZ({url: layer.url})});
  }

  startMeasurement(type, color, id) {
    let source = new VectorSource();
    let vector = new VectorLayer({
      source: source,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.6)"
        }),
        stroke: new Stroke({
          color: color,
          width: 2
        })
      })
    });
    vector.setZIndex(ZIndex.Drawing);
    this.map.addLayer(vector);

    let target = {
      id: id,
      type: type,
      layer: vector,
      area: 0,
      length: 0,
      radius: 0,
      drawing: null,
      tooltip: null,
      tooltipElement: null,
      onFinish: null,
      cancel: () => {
        if (target.onFinish) target.onFinish();
        target.drawing.abortDrawing();
        this.map.removeInteraction(target.drawing);
        this.map.removeOverlay(target.tooltip);
        this.map.removeLayer(target.layer);
      },
      remove: () => {
        this.map.removeOverlay(target.tooltip);
        this.map.removeLayer(target.layer);
      }
    };

    function createMeasureTooltip(map) {
      target.tooltipElement = document.createElement("div");
      target.tooltipElement.className = "ol-tooltip ol-tooltip-measure";
      target.tooltipElement.style.borderColor = color;
      target.tooltip = new Overlay({
        element: target.tooltipElement,
        offset: [0, -15],
        positioning: "bottom-center"
      });
      map.addOverlay(target.tooltip);
    }

    function addInteractionFun(map, type) {
      let geometryFunction;
      if (type === "Box") {
        type = "Circle";
        geometryFunction = createBox();
      }
      let draw = new Draw({
        source: source,
        type: type,
        geometryFunction: geometryFunction,
        style: new Style({
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.5)"
          }),
          stroke: new Stroke({
            // color: 'rgba(0, 0, 0, 0.5)',
            color: color,
            lineDash: [10, 5],
            width: 1
          }),
          image: new Circle({
            radius: 5,
            stroke: new Stroke({
              color: "rgba(0, 0, 0, 0.7)"
            }),
            fill: new Fill({
              color: "rgba(255, 255, 255, 0.2)"
            })
          })
        })
      });

      map.addInteraction(draw);
      createMeasureTooltip(map);
      let listener;
      draw.on("drawstart", function(evt) {
        let sketch = evt.feature;
        let tooltipCoord = evt.coordinate;
        listener = sketch.getGeometry().on("change", function(evt) {
          let geom = evt.target;
          if (geom instanceof Polygon) {
            target.length = getLength(geom);
            target.area = getArea(geom);
            tooltipCoord = geom.getInteriorPoint().getCoordinates();
          } else if (geom instanceof LineString) {
            target.length = getLength(geom);
            tooltipCoord = geom.getLastCoordinate();
          } else if (geom instanceof CircleString) {
            let poly = fromCircle(geom);
            target.length = getLength(poly);
            target.radius = target.length / (2 * Math.PI);
            target.area = target.radius * target.radius * Math.PI;
            tooltipCoord = geom.getFirstCoordinate();
          }
          if (target.tooltipElement) {
            target.tooltipElement.innerHTML = target.id;
            target.tooltip.setPosition(tooltipCoord);
          }
        });
      });
      draw.on("drawabort", function() {
        unByKey(listener);
      });
      draw.on("drawend", function() {
        if (target.onFinish) target.onFinish();
        target.tooltipElement.className = "ol-tooltip ol-tooltip-static";
        target.tooltip.setOffset([0, -7]);
        target.tooltipElement = null;
        unByKey(listener);
        map.removeInteraction(draw);
      });
      return draw;
    }

    target.drawing = addInteractionFun(this.map, type);
    return target;
  }

  zoomToExtent(extents) {
    let extent = createEmpty();
    for (let ex of extents) extend(extent, ex);
    if (!isEmpty(extent)) this.map.getView().fit(extent);
  }

  drawEpsg4326Circle(waypoints, radius = 3, width = 3) {
    waypoints = waypoints.map(p => googleMaps.transformFromLatLng(p)).map(p => new Feature({geometry: new Point(p)}));

    let source = new VectorSource({
      features: waypoints
    });
    let style = new Style({
      image: new Circle({
        radius: radius,
        fill: new Fill({color: "white"}),
        stroke: new Stroke({
          color: "rgba(100,100,100,1)",
          width: width
        })
      })
    });
    let vectorLayer = new VectorLayer({
      source: source,
      style: style,
      zIndex: 2,
      crossOrigin: "anonymous",
      updateWhileAnimating: true,
      updateWhileInteracting: true
    });
    this.map.addLayer(vectorLayer);
    return vectorLayer;
  }

  setupGlobalInteraction() {
    let popup = document.getElementById("hover-popup");
    this.popupOverlay = new Overlay({
      element: popup,
      offset: [7, 7]
    });
    this.map.addOverlay(this.popupOverlay);

    this.map.on("pointermove", event => {
      let features = [];
      this.map.forEachFeatureAtPixel(event.pixel, feature => features.push(feature), {
        layerFilter: layer => layer instanceof VectorLayer
      });

      let elem = this.popupOverlay.getElement();

      if (features.length > 0) {
        let feat = features[0];
        let subFeatures = feat.get("features");
        let message = "";
        if (subFeatures) {
          // dealing with a cluster
          if (subFeatures.length === 1) {
            message = subFeatures[0].get("poiElement").name;
          } else {
            this.basemap_cluster_ids = subFeatures
              .slice(0, subFeatures.length)
              .map(f => f.get("poiElement").id)
              .map(n => `${n}`)
              .join(",");
            message = subFeatures
              .slice(0, 3)
              .map(f => f.get("poiElement").name)
              .map(n => `- ${n}`)
              .join("<br>");

            if (subFeatures.length > 3) {
              message = message + "<br/>...";
            }
          }
        } else if (feat.poi && feat.poi.name) {
          message = feat.poi.name;
        } else {
          return;
        }
        elem.innerHTML = message;
        if (window.poiLayers.poi_cluster_ids != this.basemap_cluster_ids || (subFeatures && subFeatures.length === 1)) {
          elem.style.display = "block";
        }
        this.popupOverlay.setPosition(event.coordinate);
      } else if (features.length === 0) {
        elem.style.display = "none";
      }
    });

    // listener for zoom level change
    let currZoom = this.map.getView().getZoom();
    this.map.on("moveend", () => {
      let newZoom = this.map.getView().getZoom();
      if (currZoom != newZoom) {
        if (typeof window.poiLayers.cluster_elem !== "undefined") {
          window.poiLayers.cluster_elem.style.display = "none";
          window.poiLayers.cluster_elem.innerHTML = "";
        }
        currZoom = newZoom;
      }
    });
  }
}
export const basemap = new Basemap();
