<template>
  <div id="legend">
    <div class="title">
      <label>{{ $t("settings.legend") }}</label>
      <button class="close-button" @click="$router.go(-1)"><img src="../../assets/icons/close.png" alt="" /></button>
    </div>
    <div class="legend-img"><img :src="src" alt="" /></div>
  </div>
</template>
<script>
import Vue from "vue";
import M2wApi from "@/services/m2w_api";

export default {
  computed: {
    src: function() {
      if (Vue.config.project.sf_basemap.legend_url.match(/^https?:\/\//))
        return Vue.config.project.sf_basemap.legend_url;

      return M2wApi.host_address + Vue.config.project.sf_basemap.legend_url;
    }
  }
};
</script>
