export default {
  LayersControl: "Straturi",
  SettingsControl: "Unelte",
  settings: {
    language: {
      title: "Limbă"
    },
    functions: {
      title: "Funcții",
      print: "Printează",
      measure: "Măsurătoare"
    },
    layerLabel: "Straturi",
    transparency: "Transparenţă",
    dataPrivacy: "Impressum & Termeni şi condiţii",
    downloadMsg: "Descarca City App",
    legend: "Legenda",
    backdrop: "Fundal",
    embedLinks: "Link integrat"
  },
  buttons: {
    fullscreen: "Ecran complet",
    popout: "Desprindeți harta",
    poisAndStreets: "Puncte de interes",
    routing: "Traseu",
    close: "Închide",
    gps: "Pozitia mea"
  },
  menuPopup: {
    back: "Back to results",
    pois: "Straturi tematice",
    streets: "Străzi"
  },
  poiDetails: {
    moreInfo: "Informatii aditionale",
    openingHours: "Orar functionare",
    opened: "Deschis",
    closed: "Închis",
    showMore: "Arata mai mult",
    showLess: "arata mai putin",
    altitude: "Altitudine",
    zmin: "Cota minimă",
    zmax: "Cota maximă",
    distance: "Distanţă",
    time: "Durată",
    content: "Conţinut",
    ytitle: "Distanţă",
    xtitle: "Altitudine"
  },
  measureComponent: {
    colorSelector: "Culoare",
    shape: "Element grafic",
    measure: "Măsurătoare",
    length: "Lungimea",
    area: "Suprafata",
    radius: "Raza",
    circumference: "Circumferinţă",
    delete: "Șterge tot"
  },
  rightClickFrame: {
    startPoint: "Selecteaza punct de pornire",
    endPoint: "Selecteaza punct de sosire",
    whatIsHere: "Ce se afla aici?",
    print: "Printează",
    measure: "Măsurătoare"
  },
  routeFrame: {
    routeOptions: "Optiuni traseu",
    highway: "Autostrazi",
    tollRoad: "Sosele cu taxa",
    ferry: "Bacuri",
    units: "Unitate de masura",
    startPoint: "De la ...",
    midPoint: "Midpoint ...",
    endPoint: "Spre ...",
    metric: "Metric (km)",
    imperial: "Imperial (mi)",
    via: "via",
    departure: "Plecare:",
    details: "Detalii",
    error: "A apărut o eroare:",
    getDirections: "Calculeaza traseu",
    switchPoints: "Switch waypoints",
    close: "Închideți vizualizarea traseului",
    directions_car: "Cu masina",
    directions_bus: "Cu transportul public",
    directions_bike: "Cu bicicleta",
    directions_walk: "La pas"
  },
  streetEntry: {num: "No."},
  searchPlaceholder: "Caută ...",
  searchFrame: {
    previous: "anterioara",
    next: "urmatoarea",
    loading: "Încărcare",
    error: "A apărut o eroare",
    noResults: "Nici un rezultat gasit",
    pois: "Obiecte",
    streets: "Străzi",
    other: "Altele"
  },
  general: {
    monday: "Luni",
    tuesday: "Marți",
    wednesday: "Miercuri",
    thursday: "Joi",
    friday: "Vineri",
    saturday: "Sâmbătă",
    sunday: "Duminică",
    copy: "Copiaza"
  },
  printOverlay: {
    titlePlaceholder: "Titlu (opțional)",
    notesPlaceholder: "Descriere (opțional)",
    print: "Printează",
    close: "Închide",
    pleaseWait: "Va rugam sa asteptati cateva secunde pentru generarea documentului.",
    pleaseWaitSubscript: ""
  },
  gdpr: {
    title: "Notificare GDPR",
    message:
      "Acest site folosește cookie-uri pentru a îmbunătăți experiența utilizatorului. Cu „Ok” confirmați că această evaluare poate fi efectuată ",
    ok: "Ok",
    no: "nu, mulțumesc"
  },
  wfs: {
    intro_description: "Vă rugăm să selectați un element de pe hartă pentru a vedea detaliile acestuia.",
    no_elem_found: "Nu a fost găsit niciun element în această locație.",
    unknown_error: "A apărut o eroare necunoscută: "
  }
};
