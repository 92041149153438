<template>
  <div id="search-frame">
    <ul class="tabs">
      <li :class="{active: activeTab === 'pois'}" @click="switchTab('pois')">
        {{ $t("searchFrame.pois") }} ({{ searchResults.poi_total }})
      </li>
      <li :class="{active: activeTab === 'streets'}" @click="switchTab('streets')">
        {{ $t("searchFrame.streets") }} ({{ searchResults.streets_total }})
      </li>
      <li :class="{active: activeTab === 'other'}" @click="switchTab('other')">
        {{ $t("searchFrame.other") }} ({{ searchResults.others_total }})
      </li>
    </ul>

    <div class="search-results pois" v-if="activeTab === 'pois'">
      <div class="no-results txt" v-if="hasError">{{ $t("searchFrame.error") }}</div>
      <div class="no-results txt" v-if="!searchResults.pois || searchResults.pois.length === 0">
        {{ $t("searchFrame.noResults") }}
      </div>
      <div class="poi-result" v-for="poi in searchResults.pois" :key="poi.id" @click="choosePoi(poi)">
        <img class="search-category-logo" v-if="poi.logo" :src="host_address + poi.logo" alt="" />
        <div class="search-category-logo" v-else></div>
        <div>
          <div class="name">{{ poi.name }}</div>
          <div class="address" v-if="poi.address">{{ poi.address[0] }}, {{ poi.address[1] }}</div>
          <div class="toc-location">
            <div @click.stop class="toc" v-for="cat in poi.toc_hierarchy.slice(0, 2).reverse()" :key="cat.id">
              <router-link :to="{name: 'pois', params: {view: 'pois'}, query: {'toc-id': cat.id}}">
                {{ cat.name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="search-results streets" v-if="activeTab === 'streets'">
      <div class="no-results txt" v-if="hasError">{{ $t("searchFrame.error") }}</div>
      <div class="no-results txt" v-if="!searchResults.streets || searchResults.streets.length === 0">
        {{ $t("searchFrame.noResults") }}
      </div>
      <StreetEntry
        v-for="street in searchResults.streets"
        v-bind:key="street.id"
        v-bind:street="street"
        v-bind:active="chosenStr === street.id"
        @click.native="chooseStreet(street.id)"
      >
      </StreetEntry>
    </div>

    <div class="search-results others" v-if="activeTab === 'other'">
      <div class="no-results txt" v-if="hasError">{{ $t("searchFrame.error") }}</div>
      <div class="no-results txt" v-if="!searchResults.others || searchResults.others.length === 0">
        {{ $t("searchFrame.noResults") }}
      </div>
      <div class="other-result" v-for="other in searchResults.others" :key="other.id" @click="choosePoi(other)">
        <div class="name">{{ other.name }}</div>
        <div class="toc-location">
          <div class="toc" v-for="cat in other.toc_hierarchy.slice(0, 2).reverse()" :key="cat.id">
            {{ cat.name }}
          </div>
        </div>
      </div>
    </div>

    <div class="search-nav">
      <button class="load btn" :disabled="page <= 0" @click="previousPage()">
        &lt; {{ $t("searchFrame.previous") }}
      </button>
      |
      <button class="load btn" :disabled="!hasMore" @click="nextPage()">{{ $t("searchFrame.next") }} &gt;</button>
    </div>
  </div>
</template>
<script>
import M2wApi from "@/services/m2w_api";
import StreetEntry from "@/components/page_elements/StreetEntry";

export default {
  name: "SearchFrame",
  components: {StreetEntry},
  data() {
    return {
      host_address: M2wApi.host_address,
      hasError: false,
      chosenStr: 0,
      page: 0,
      pageSize: 10,
      searchResults: {},
      searchTerm: null,
      activeTab: "pois",
      location: null
    };
  },
  computed: {
    hasMore() {
      const currentHits = this.pageSize * (this.page + 1);
      switch (this.activeTab) {
        case "pois":
          return currentHits < this.searchResults.poi_total;
        case "streets":
          return currentHits < this.searchResults.streets_total;
        case "other":
          return currentHits < this.searchResults.others_total;
      }
      return false;
    }
  },
  async mounted() {
    await this.doSearch();
  },
  deactivated() {
    this.$layers.teardownCategory(this.searchResults);
    this.location = null;
  },
  activated() {
    if (this.searchResults && this.activeTab === "pois") {
      this.$layers.setupCategory(this.searchResults, this.choosePoi);
    }
  },
  watch: {
    "$route.query.q"(val) {
      if (val) this.doSearch();
    },
    "$route.query.page"(val) {
      if (!isNaN(val)) this.doSearch();
    }
  },
  methods: {
    async doSearch() {
      this.$Progress.start();
      this.hasError = false;
      const q = this.$route.query;
      this.searchTerm = q.q;
      this.page = parseInt(q.page, 10);
      if (this.location === null) this.location = q.location;

      try {
        let results = await M2wApi.get_search_results(
          this.searchTerm,
          this.page,
          this.pageSize,
          this.location,
          this.$i18n.locale
        );

        this.replaceResults(results);
      } catch (e) {
        this.$Progress.fail();
        this.hasError = true;
        throw e;
      }
      this.$Progress.finish();
    },

    async nextPage() {
      if (this.hasMore) await this.$router.push({query: {...this.$route.query, page: this.page + 1}});
    },
    async previousPage() {
      if (this.page > 0) await this.$router.push({query: {...this.$route.query, page: this.page - 1}});
    },

    async choosePoi(poi) {
      this.$layers.teardownCategory(this.searchResults);

      let query = this.$route.query;
      delete this.$route.query.parent;
      await this.$router.push({
        name: "poiDetails",
        params: {id: poi.id},
        query: query
      });
    },

    replaceResults(newResults) {
      this.$layers.teardownCategory(this.searchResults);
      this.searchResults = newResults;

      if (this.activeTab === "pois") {
        this.$layers.setupCategory(this.searchResults, this.choosePoi);
      }
    },

    chooseStreet(street) {
      // click on street again to hide it
      if (this.chosenStr === street) {
        this.chosenStr = 0;
      } else {
        this.chosenStr = street;
      }
    },

    async switchTab(tab) {
      if (tab === this.activeTab) return;

      this.activeTab = tab;
      this.$layers.teardownCategory(this.searchResults);
      if (tab === "pois") {
        this.$layers.setupCategory(this.searchResults, this.choosePoi);
      }
      if (this.page !== 0) {
        await this.$router.push({query: {...this.$route.query, page: 0}});
      }
    }
  }
};
</script>
