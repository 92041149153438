<template>
  <div class="imprint-links">
    <a target="_blank" href="https://www.schubert-franzke.com">&copy; Schubert &amp; Franzke</a>
    <a target="_blank" href="https://www.schubert-franzke.com/m2wimpressum/">{{ $t("settings.dataPrivacy") }}</a>
    <router-link to="/legend" v-if="hasBasemap">{{ $t("settings.legend") }}</router-link>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "ImprintLinks",
  data() {
    return {
      hasBasemap: !!Vue.config.project.sf_basemap
    };
  }
}
</script>

<style scoped lang="scss">
.imprint-links>a {
  font-size: 10px;
  text-decoration: none;
  color: #464646;

  &::after {
    padding: 0 10px;
    content: "|";
  }

  &:last-child::after {
    padding: 0;
    content: "";
  }
}

</style>