<template>
  <div class="gdpr-message" v-if="acceptancePending && !$root.$data.embedded">
    <h1>{{$t("gdpr.title")}}</h1>
    <p>{{$t("gdpr.message")}}</p>
    <div class="buttons">
      <button @click="setAcceptance(true)" style="font-weight: bold; background-color: #ad3">{{$t("gdpr.ok")}}</button>
      <button @click="setAcceptance(true)">{{ $t("gdpr.no") }}</button>
    </div>
  </div>
</template>

<script>
import VueCookie from "vue-cookie";
import { bootstrap } from 'vue-gtag'

export default {
  name: "GdprPopup",
  data() {
    return {
      acceptancePending: false
    }
  },

  created() {
    // read cookie and set acceptancePending
    let acceptance = VueCookie.get("gdprAcceptance");
    this.acceptancePending = !acceptance;
    if(!this.acceptancePending) {
      this.setAcceptance(acceptance.toLowerCase() === 'true');
    }
  },

  methods: {
    async setAcceptance(accepted) {
      this.acceptancePending = false;
      VueCookie.set("gdprAcceptance", accepted.toString(), Infinity);

      if(accepted) {
        await bootstrap();
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/base";
.gdpr-message {
  @include frame;
  @include font;
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  max-width: 400px;
  z-index: 1000;
  h1 {
    text-align: center;
    margin-bottom: 5px;
  }
  p {
    font-weight: normal;
    font-size: 80%;
    margin-bottom: 5px;
  }
  .buttons{
    display: flex;
    justify-content: center;

    button{
      @include font;
      @include frame;
      box-shadow: none;
      font-weight: normal;
      font-size: 80%;
      margin: 5px;
      padding: 5px 10px;
      width: 40%;
    }
  }
}
</style>