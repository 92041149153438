import Feature from "ol/Feature";
import {Point} from "ol/geom";
import {Cluster, Vector as VectorSource} from "ol/source";
import {Circle, Fill, Icon, Stroke, Style, Text} from "ol/style";
import M2wApi from "@/services/m2w_api";
import {Vector as VectorLayer} from "ol/layer";
import Select from "ol/interaction/Select";
import CircleStyle from "ol/style/Circle";
import {basemap} from "@/services/map/basemap";
import GeoJSON from "ol/format/GeoJSON";
import CategoryService from "@/services/category";
import {pointerMove} from "ol/events/condition";
import {createEmpty, extend, isEmpty} from "ol/extent";
import ZIndex from "@/services/zindex";
import MultiPoint from "ol/geom/MultiPoint";
import Overlay from "ol/Overlay";


export default class {

  install(vue) {
    vue.prototype.$layers = this;
    window.poiLayers = this;
    this.vue = vue;
  }

  // fields:
  // * overlayLayer
  // * clusterLayer, clusterInteraction
  // * shapesLayer, shapesInteractions
  async setupCategory(category, onclickCallback) {
    if (!category.color) {
      if (category.style) {
        category.color = basemap.toRgba(category.style.fill_color, 40); // 60% is hardcoded transparency in category service
      } else {
        category.color = CategoryService.getClusterColor();
      }
    }

    await this.addCategoryLayer(category);
    let pois = (category.children || category.pois || []).filter(el => el.geom);

    // split items into point features and shape features
    let points = [];
    let shapes = [];
    pois.forEach(p => (p.geom.type === "Point" ? points.push(p) : shapes.push(p)));

    this.addClusterLayer(category, points, onclickCallback);
    this.addShapesLayer(category, shapes, onclickCallback);

    let extents = [
      category.clusterLayer
        .getSource()
        .getSource()
        .getExtent(),
      category.shapesLayer.getSource().getExtent()
    ];

    let extent = createEmpty();
    for (let ex of extents) extend(extent, ex);
    if (!isEmpty(extent)) basemap.map.getView().fit(extent, {duration: 500});
  }

  teardownCategory(category) {
    this.deleteCategoryLayer(category);
    this.deleteClusterLayer(category);
    this.deleteShapesLayer(category);
  }

  async setupSinglePoi(poi) {
    let feature = this.createFeatureForPoi(poi);
    let layer = this.createLayerFromFeatures([feature]);
    poi.layer = layer;

    if (poi.parent.style) {
      poi.hoverInteraction = this.createHoverInteraction(poi.parent.style, layer);
    }

    if (poi.parent.layer) {
      await this.addCategoryLayer(poi.parent);
    }
    return layer.getSource().getExtent();
  }

  teardownSinglePoi(poi) {
    if (!poi) return;

    if (poi.hoverInteraction) basemap.map.removeInteraction(poi.hoverInteraction);
    if (poi.layer) basemap.map.removeLayer(poi.layer);

    if (poi.parent.layer) {
      this.deleteCategoryLayer(poi.parent);
    }

    poi.hoverInteraction = null;
    poi.layer = null;
  }

  //region category Layer
  async addCategoryLayer(category) {
    if (!category.layer)
      // no layer defined
      return;
    if (category.overlayLayer)
      // layer already present
      return;

    if (category.layer.type == "wmts") {
      let newLayer = await basemap.getWmtsLayer(category.layer);
      newLayer.setZIndex(ZIndex.TocLayer);
      basemap.map.addLayer(newLayer);
      category.overlayLayer = newLayer;
    } else if (category.layer.type == "wms") {
      let newLayer = await basemap.getWmsLayer(category.layer);
      newLayer.setZIndex(ZIndex.TocLayer);
      basemap.map.addLayer(newLayer);
      category.overlayLayer = newLayer;
    } else if (category.layer.type == "xyz") {
      let newLayer = await basemap.getXyzLayer(category.layer);
      newLayer.setZIndex(ZIndex.TocLayer);
      basemap.map.addLayer(newLayer);
      category.overlayLayer = newLayer;
    } else {
      console.log("Layer type not supported");
    }
  }

  deleteCategoryLayer(category) {
    if (category.overlayLayer) {
      basemap.map.removeLayer(category.overlayLayer);
      category.overlayLayer = null;
    }
  }
  //endregion

  //region cluster layer
  // clusterLayer, clusterInteraction
  addClusterLayer(category, pois, onclickCallback) {
    if (category.clusterLayer)
      // clusterLayer already present -> skip
      return;

    // point items go into a cluster
    let features = pois.map(
      el =>
        new Feature({
          geometry: new Point(el.geom.coordinates),
          poiElement: el
        })
    );

    let poiSource = new VectorSource({
      features: features
    });

    let clusterSource = new Cluster({
      distance: 50,
      source: poiSource
    });

    function buildSinglePoiStyle(feature) {
      return new Style({
        geometry: feature.getGeometry(),
        image: new Icon({
          anchor: [0.5, 1],
          crossOrigin: "anonymous",
          // in search, features themselves have markers, in the TOC, only the parent does.
          src: M2wApi.host_address + (feature.get("poiElement").marker || category.marker)
        })
      });
    }

    let self = this;
    let clusters = new VectorLayer({
      source: clusterSource,
      updateWhileInteracting: true,
      updateWhileAnimating: true,
      style: feature => {
        let features = feature.get("features");
        let size = features.length;

        let clustering = self.vue.config.item_clustering || self.vue.cookie.get("item_clustering") || "dynamic";

        // single POI
        if (clustering == "never" || (size === 1 && clustering != "always"))
          return features.map(f => buildSinglePoiStyle(f));

        // cluster
        let radius = Math.min(17 + size/2, 25);
        return new Style({
          image: new Circle({
            radius: radius,
            stroke: new Stroke({
              color: "#fff"
            }),
            fill: new Fill({
              color: category.color
            })
          }),
          text: new Text({
            text: size.toString(),
            fill: new Fill({
              color: "#fff"
            }),
            font: "14px sans-serif"
          })
        });
      },
      zIndex: 2
    });
    category.clusterLayer = clusters;
    basemap.map.addLayer(clusters);

    let clickInteraction = new Select({
      condition: evt => evt.type === "click",
      layers: [clusters],
      style: function(feature) {
        let features = feature.get("features");

        let styles = [
          new Style({
            image: new CircleStyle({
              radius: feature.get("radius"),
              fill: new Fill({color: "rgba(255, 255, 255, 0.01)"})
            })
          })
        ];
        return styles.concat(features.map(f => buildSinglePoiStyle(f)));
      }
    });

    let clusterPopup = document.getElementById("cluster-popup");
    let clusterOverlay = new Overlay({
      element: clusterPopup,
      offset: [7, 7]
    });
    this.cluster_elem = clusterOverlay.getElement();
    basemap.map.addOverlay(clusterOverlay);

    if (onclickCallback) {
      clickInteraction.on("select", e => {
        let popup_elem = basemap.popupOverlay.getElement();

        // current cluster is closed by clicking somewhere on the map
        if (!e.selected.length) {
          this.cluster_elem.style.display = "none";
          this.poi_cluster_ids = "";
          this.cluster_elem.innerHTML = "";
          return;
        }
        let features = e.selected[0].get("features");

        // cluster is opened
        if (features.length !== 1) {
          this.cluster_elem.innerHTML = "";
          this.poi_cluster_ids = features
            .slice(0, features.length)
            .map(f => f.get("poiElement").id)
            .map(n => `${n}`)
            .join(",");
          let cluster_coord = e.selected[0].values_.geometry.flatCoordinates;
          clusterOverlay.setPosition(cluster_coord);
          features.forEach(element => {
            let curr_element = element.get("poiElement");
            this.cluster_elem.innerHTML += `<li><button id="id_${curr_element.id}" type="button">${curr_element.name}</button></li>`;
          });
          features.forEach(element => {
            let curr_element = element.get("poiElement");
            let button_id = "id_" + curr_element.id;
            document.getElementById(button_id).onclick = function() {
              onclickCallback(curr_element);
            };
          });

          this.cluster_elem.style.display = "block";
          popup_elem.style.display = "none";
          return;
        }

        let poi_elem = features[0].get("poiElement");
        this.cluster_elem.style.display = "none";
        onclickCallback(poi_elem);
      });
    }

    category.clusterInteraction = clickInteraction;
    basemap.map.addInteraction(clickInteraction);
  }

  deleteClusterLayer(category) {
    if (category.clusterLayer) basemap.map.removeLayer(category.clusterLayer);

    if (category.clusterInteraction) basemap.map.removeInteraction(category.clusterInteraction);

    this.cluster_elem.style.display = "none";
    category.clusterLayer = null;
    category.clusterInteraction = null;
  }
  //endregion

  //region shapes layer
  addShapesLayer(category, shapes, onclickCallback) {
    if (category.shapesLayer) return;

    let shapeFeatures = shapes.map(x => this.createFeatureForPoi(x));
    let vectorLayer = this.createLayerFromFeatures(shapeFeatures);
    category.shapesLayer = vectorLayer;

    // hover interaction
    category.shapesInteractions = [];
    if (category.style) {
      let hover = this.createHoverInteraction(category.style, vectorLayer);
      category.shapesInteractions.push(hover);
    }

    // click interaction
    if (onclickCallback) {
      let clickInteraction = new Select({
        condition: evt => evt.type === "click",
        multi: true,
        hitTolerance: 5,
        layers: [vectorLayer]
      });
      clickInteraction.on("select", e => {
        if (!e.selected.length) return;

        let elem = e.selected[0].poi;
        onclickCallback(elem);
      });
      basemap.map.addInteraction(clickInteraction);
      category.shapesInteractions.push(clickInteraction);
    }
  }

  deleteShapesLayer(category) {
    if (category.shapesLayer) basemap.map.removeLayer(category.shapesLayer);
    category.shapesLayer = null;

    if (category.shapesInteractions) category.shapesInteractions.forEach(i => basemap.map.removeInteraction(i));
    category.shapesInteractions = null;
  }
  //endregion

  createFeatureForPoi(poi) {
    let feature;
    switch (poi.geom.type) {
      case "Point":
        feature = this.createPointFeature(poi.geom.coordinates, poi.marker || poi.parent?.marker);
        break;
      case "MultiPoint":
        feature = this.createMultiPointFeature(poi.geom.coordinates, poi.marker || poi.parent?.marker);
        break;
      case "Polygon":
      case "MultiPolygon":
        feature = this.createPolygonFeature(poi.geom, poi.style);
        break;
      case "LineString":
      case "MultiLineString":
        feature = this.createLineStringFeature(poi.geom, poi.style);
        break;
    }

    feature.poi = poi;
    poi.feature = feature;
    return feature;
  }

  createMultiPointFeature(coordinates, markerUrl) {
    let marker = new Feature({
      geometry: new MultiPoint(coordinates)
    });
    let markerStyle = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        crossOrigin: "anonymous",
        src: M2wApi.host_address + markerUrl
      })
    });
    marker.setStyle(markerStyle);
    return marker;
  }

  createPointFeature(coordinates, markerUrl) {
    let marker = new Feature({
      geometry: new Point(coordinates)
    });
    let markerStyle = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        crossOrigin: "anonymous",
        src: M2wApi.host_address + markerUrl
      })
    });
    marker.setStyle(markerStyle);
    return marker;
  }

  createPolygonFeature(geom, polyStyle) {
    if (!polyStyle) {
      polyStyle = {
        fill_color: "#3355ff",
        line_color: "#3355ff",
        fill_transparency: 70,
        line_thickness: 1,
        line_transparency: 0
      };
    }

    let poly = new Feature({
      geometry: new GeoJSON().readGeometry(geom)
    });
    let style = new Style({
      stroke: new Stroke({
        color: basemap.toRgba(polyStyle.line_color, polyStyle.line_transparency),
        width: polyStyle.line_thickness
      }),
      fill: new Fill({
        color: basemap.toRgba(polyStyle.fill_color, polyStyle.fill_transparency)
      })
    });

    poly.setStyle(style);
    return poly;
  }

  createLineStringFeature(geom, style) {
    let line = new Feature({
      geometry: new GeoJSON().readGeometry(geom)
    });

    let olStyle = new Style({
      stroke: new Stroke({
        width: style.line_thickness,
        color: basemap.toRgba(style.line_color, style.line_transparency)
      })
    });

    line.setStyle(olStyle);
    return line;
  }

  createHoverInteraction(style, layer) {
    let hoverSelect = new Select({
      condition: pointerMove,
      layers: [layer],
      style: new Style({
        stroke: new Stroke({
          color: basemap.toRgba(style.line_color, style.line_transparency),
          width: style.line_thickness
        }),
        fill: new Fill({
          color: basemap.toRgba(style.fill_color, style.fill_transparency)
        })
      })
    });
    basemap.map.addInteraction(hoverSelect);
    return hoverSelect;
  }

  createLayerFromFeatures(shapeFeatures) {
    let source = new VectorSource({
      features: shapeFeatures,
      crossOrigin: "anonymous"
    });
    let vectorLayer = new VectorLayer({
      source: source,
      updateWhileAnimating: true,
      updateWhileInteracting: true
    });
    vectorLayer.setZIndex(ZIndex.Marker);
    basemap.map.addLayer(vectorLayer);
    return vectorLayer;
  }
}
