<template>
  <div id="menu-popup">
    <div id="main-wrapper">
      <!-- menu and category header -->
      <ul class="tabs" v-if="headerVisible && !$root.$data.embedded && categoryFrames.length > 1">
        <li
          v-for="frame in categoryFrames"
          :key="frame"
          :class="{active: currentComponent === frame}"
          @click="updateFrame(frame)"
        >
          {{ $t("menuPopup." + frame) }}
        </li>
      </ul>
      <!-- component frame -->
      <keep-alive>
        <component :is="currentTabComponent" @showHeader="showHeader" />
      </keep-alive>
    </div>
  </div>
</template>
<script>
import CategoriesFrame from "./CategoriesFrame.vue";
import StreetDirectoryFrame from "./StreetDirectoryFrame";
import Vue from "vue";

export default {
  name: "MenuPopup",
  components: {CategoriesFrame, StreetDirectoryFrame},
  data() {
    return {
      currentComponent: "pois",
      headerVisible: true
    };
  },
  watch: {
    "$route.params.view": function(view) {
      if (view && this.categoryFrames.includes(view)) this.currentComponent = view;
    }
  },
  async created() {
    let view = this.$route.params["view"];
    if (view && this.categoryFrames.includes(view)) this.currentComponent = view;
  },

  computed: {
    currentTabComponent: function() {
      switch (this.currentComponent) {
        case "pois":
          return "CategoriesFrame";
        case "streets":
          return "StreetDirectoryFrame";
        default:
          return null;
      }
    },
    categoryFrames: function() {
      if(Vue.config.project.with_streets)
        return ["pois", "streets"];
      else
        return ["pois"];
    }
  },

  methods: {
    async updateFrame(frame) {
      this.currentComponent = frame;
      await this.$router.push({params: {view: frame}, query: this.$route.query});
    },

    showHeader(visible) {
      this.headerVisible = visible;
    }
  }
};
</script>
