export default class EmbedHelper {

  static getEmbedUri() {
    const currentUri = window.location;
    if (currentUri.search) {
      return window.location.href + "&embedded=true";
    }
    return window.location.href + "?embedded=true";
  }

  static getStandalone() {
    const currentUri = window.location.href;
    let rx = /embedded=true[&]?/;
    return currentUri.replace(rx, "");
  }

  static getIframeCode() {
    return `<iframe src="${this.getEmbedUri()}" title="${document.title}" width="750" height="550"></iframe>`;
  }
}