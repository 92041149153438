export default {
  LayersControl: "Rétegek",
  SettingsControl: "Beállítások",
  settings: {
    language: {
      title: "Nyelv"
    },
    functions: {
      title: "Eszközök",
      print: "Nyomtatás",
      measure: "Mérés"
    },
    layerLabel: "Rétegek",
    transparency: "Áttetszőség",
    dataPrivacy: "Impresszum és adatvédelmi záradék",
    downloadMsg: "Töltse le a City App alkalmazást",
    legend: "Jelmagyarázat",
    backdrop: "Háttér (térkép)",
    embedLinks: "Link a beágyazáshoz"
  },
  buttons: {
    fullscreen: "Teljes képernyő",
    popout: "Térkép megnyitása új ablakban",
    poisAndStreets: "Látványosság",
    routing: "Navigáció",
    close: "Elzár"
  },
  menuPopup: {
    back: "Back to results",
    pois: "Tematikus rétegek",
    streets: "Utcák"
  },
  poiDetails: {
    moreInfo: "Több információ",
    openingHours: "Nyitvatartási idő",
    opened: "Opened",
    closed: "Closed",
    showMore: "Mutass többet",
    showLess: "Mutass kevesebbet",
    altitude: "Magasság",
    zmin: "Min. magasság",
    zmax: "Max. magasság",
    distance: "Távolság",
    time: "Time",
    content: "Tartalom",
    ytitle: "Távolság",
    xtitle: "Magasság"
  },
  measureComponent: {
    colorSelector: "Szín",
    shape: "Rajzelem",
    measure: "Mérés",
    length: "Hossz",
    area: "Terület",
    radius: "Sugár",
    circumference: "Kerület",
    delete: "Mindet törölni"
  },
  rightClickFrame: {
    startPoint: "Válasszon kiindulási pontot",
    endPoint: "Válasszon végpontot",
    whatIsHere: "Mi található itt?",
    print: "Nyomtatás",
    measure: "Mérés"
  },
  routeFrame: {
    routeOptions: "Útlehetőségek",
    highway: "Autópályák",
    tollRoad: "Fizetős utak",
    ferry: "Kompok",
    units: "Egység",
    startPoint: "Útvonal innen ...",
    midPoint: "Midpoint ...",
    endPoint: "Útvonal ide ...",
    metric: "Metrikus (km)",
    imperial: "Birodalmi (mi)",
    via: "keresztül",
    departure: "Indulás:",
    details: "Részletek",
    error: "Hiba történt:",
    getDirections: "Útvonal kiszámítása",
    switchPoints: "Fordított útvonal",
    close: "Navigáció kikapcsolása",
    directions_car: "Autóval",
    directions_bus: "Tömegközlekedéssel",
    directions_bike: "Kerékpárral",
    directions_walk: "Gyalog"
  },
  streetEntry: {num: "H.sz."},
  searchPlaceholder: "Keresés ...",
  searchFrame: {
    previous: "Előző",
    next: "Következő",
    loading: "Loading",
    error: "Hiba történt",
    noResults: "Nem található találat",
    pois: "Látnivaló",
    streets: "Utcák",
    other: "Egyéb"
  },
  general: {
    monday: "Hétfő",
    tuesday: "Kedd",
    wednesday: "Szerda",
    thursday: "Csütörtök",
    friday: "Péntek",
    saturday: "Szombat",
    sunday: "Vasárnap",
    copy: "Másolás"
  },
  printOverlay: {
    titlePlaceholder: "Cím (választható)",
    notesPlaceholder: "Jegyzetek (választható)",
    print: "Nyomtatás",
    close: "Bezárás"
  },
  wfs: {
    intro_description: "Kérjük, válasszon ki egy elemet a térképen a részletek megtekintéséhez.",
    no_elem_found: "Ezen a helyen nem találtunk elemet.",
    unknown_error: "Ismeretlen hiba történt: "
  }
};
