<template>
  <div class="street" :class="{active: active, deselected: deselected}">
    <p>{{ street.name }}</p>
    <select v-model="selected" @click.stop>
      <option value="" disabled selected>{{ $t("streetEntry.num") }}</option>
      <option v-for="nr in houseNumbers" :key="nr.id" :value="nr.hnr">{{ nr.hnr }}</option>
    </select>
  </div>
</template>

<script>
import {basemap} from "@/services/map/basemap";
import M2wApi from "@/services/m2w_api";

export default {
  name: "StreetEntry",
  props: ["street", "active", "deselected"],
  data() {
    return {
      houseNumbers: [],
      activeLine: null,
      activeMarker: null,
      selected: "",
      hnr: null
    };
  },
  watch: {
    active: async function(val, oldval) {
      if (val != oldval) {
        this.cleanup();
      }
      await this.onActiveFunc();
    },

    selected: function(val) {
      if (val !== "" && this.$route.query.hnr !== val) {
        this.$router.replace({query: {...this.$route.query, hnr: val}});
        this.hnrFromQuery();
      }
    },

    "$route.query.hnr": function() {
      this.hnrFromQuery();
    }
  },

  async mounted() {
    await this.onActiveFunc();
  },
  async activated() {
    await this.onActiveFunc();
  },
  deactivated() {
    this.cleanup();
  },
  destroyed() {
    this.cleanup();
  },

  methods: {
    hnrFromQuery() {

      if (this.activeMarker) basemap.map.removeLayer(this.activeMarker);

      if (!this.active) {
        // this.cleanup();
        return;
      }

      let hnr = this.$route.query.hnr;
      let num = this.houseNumbers.find(x => x.hnr === hnr);
      if (!num) {
        return;
      }

      this.hnr = num;
      this.selected = hnr;
      this.activeMarker = basemap.createMarkerForCoordinates(num.geom.coordinates);
      basemap.map.getView().animate({
        center: num.geom.coordinates,
        duration: 200
      });
    },

    async onActiveFunc() {

      if (this.activeLine) basemap.map.removeLayer(this.activeLine);

      if (!this.active) {
        // this.cleanup();
        return;
      }

      this.activeLine = basemap.drawLine(this.street.geom);
      let extent = this.activeLine.getSource().getExtent();
      basemap.map.getView().fit(extent, {duration: 1000});

      // fetch HNRs if necessary
      if (this.houseNumbers.length === 0) {
        this.houseNumbers = (await M2wApi.get_single_street(this.street.id)).house_numbers;
      }

      this.$el.scrollIntoView({block: "nearest"});
      this.hnrFromQuery();
    },

    async chooseHnr(num_obj) {
      await this.$router.push({query: {...this.$route.query, hnr: num_obj.hnr}});
    },

    cleanup() {
      this.selected = "";
      this.hnr = null;
      if (this.activeMarker) basemap.map.removeLayer(this.activeMarker);
      if (this.activeLine) basemap.map.removeLayer(this.activeLine);
      
      this.$router.replace({query: {...this.$route.query, hnr: null}});
    }
  }
};
</script>

<style scoped></style>
