<template>
  <div class="copyableInput">
    <input v-model="inputData" :title="inputData" />
    <button @click="copy(inputData)" :title="$t('general.copy')" class="material-icons-round md-20">copy</button>
  </div>
</template>

<script>

import Clipboard from "@/services/clipboard";

export default {
  name: "CopyableInput",
  props: ['inputData'],
  methods: {
    async copy(text) {
      /*let result = */await Clipboard.copy(text);
      // todo: evaluate the result to show some success popup
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/css/base";

.copyableInput {
  border: $frame_border;
  display: flex;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  border-radius: 5px;

  > :first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  > :last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  > :not(:last-child){
    border-right: $frame_border;
  }

  input {
    padding: 0 5px;
    height: 30px;
    @include font;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    outline: none;
    border: none;
    background: none;
    flex-grow: 1;
  }

  button {
    flex-grow: 0;
    width: 35px;
    height: 30px;
    background-color: $light_background-color;
    &:active {
      filter: brightness(0.9);
    }
  }
}
</style>