export default {
  LayersControl: "Layers",
  SettingsControl: "Settings",
  settings: {
    language: {
      title: "Language",
      German: "Deutsch",
      English: "English",
      Hungarian: "Magyar",
      Romanian: "Română"
    },
    clustering: {
      title: "Item clustering",
      always: "Always",
      never: "Never",
      dynamic: "Dynamic"
    },
    functions: {
      title: "Functions",
      print: "Print",
      measure: "Measure"
    },
    layerLabel: "Layer",
    transparency: "Transparency",
    dataPrivacy: "Imprint & Data Privacy",
    downloadMsg: "Download City App",
    legend: "Legend",
    backdrop: "Backdrop",
    embedLinks: "Embed-Links"
  },
  buttons: {
    fullscreen: "Toggle Fullscreen",
    popout: "Detach map",
    poisAndStreets: "Points of Interest",
    routing: "Routing",
    close: "Close",
    gps: "My Location"
  },
  menuPopup: {
    back: "Back to results",
    pois: "Categories",
    streets: "Streets"
  },
  poiDetails: {
    moreInfo: "More information",
    openingHours: "Opening hours",
    opened: "Opened",
    closed: "Closed",
    showMore: "Show more",
    showLess: "Show less",
    altitude: "Altitude",
    zmin: "Min. Elevation",
    zmax: "Max. Elevation",
    distance: "Distance",
    time: "Time",
    content: "Content",
    ytitle: "Distance",
    xtitle: "Altitude"
  },
  measureComponent: {
    colorSelector: "Color",
    shape: "Shape",
    measure: "Measure",
    length: "Length",
    area: "Area",
    radius: "Radius",
    circumference: "Circumference",
    delete: "Delete all"
  },
  rightClickFrame: {
    startPoint: "Choose startpoint",
    endPoint: "Choose endpoint",
    whatIsHere: "What is here?",
    print: "Print",
    measure: "Measure"
  },
  routeFrame: {
    routeOptions: "Route options",
    highway: "Highways",
    tollRoad: "Toll roads",
    ferry: "Ferries",
    units: "Units",
    startPoint: "Startpoint ...",
    midPoint: "Midpoint ...",
    endPoint: "Endpoint ...",
    metric: "Metric (km)",
    imperial: "Imperial (mi)",
    via: "via",
    departure: "Departure:",
    details: "Details",
    error: "An error has occurred:",
    getDirections: "Go!",
    switchPoints: "Switch waypoints",
    close: "Close route view",
    directions_car: "Driving",
    directions_bus: "Transit",
    directions_bike: "Cycling",
    directions_walk: "Walking"
  },
  streetEntry: {num: "No."},
  searchPlaceholder: "Search ...",
  searchFrame: {
    previous: "previous",
    next: "next",
    loading: "Loading",
    error: "An error has occurred",
    noResults: "Sorry, no results found",
    pois: "POIs",
    streets: "Streets",
    other: "Other"
  },
  general: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    copy: "Copy to clipboard"
  },
  printOverlay: {
    titlePlaceholder: "Title (optional)",
    notesPlaceholder: "Notes (optional)",
    print: "Print",
    close: "Close",
    pleaseWait: "Please wait while your print is generated",
    pleaseWaitSubscript: "This might take several seconds."
  },
  gdpr: {
    title: "GDPR Notification",
    message:
      "This site collects anonymous statistics to improve the user experience. By pressing 'Ok' you consent to this analysis.",
    ok: "Ok",
    no: "No thanks"
  },
  wfs: {
    intro_description: "Please select an element on the map to view its details.",
    no_elem_found: "No element was found at this location.",
    unknown_error: "An unknown error occurred: "
  }
};
