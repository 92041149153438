export default {
  LayersControl: "Ebenen",
  SettingsControl: "Einstellungen",
  settings: {
    language: {
      title: "Sprache"
    },
    clustering: {
      title: "Clusterung",
      always: "Immer",
      never: "Niemals",
      dynamic: "Dynamisch"
    },
    functions: {
      title: "Funktionen",
      print: "Drucken",
      measure: "Messen"
    },
    layerLabel: "Karten-Ebenen",
    transparency: "Transparenz",
    dataPrivacy: "Impressum & Datenschutz",
    downloadMsg: "Jetzt City App herunterladen",
    legend: "Legende",
    backdrop: "Hintergrund",
    embedLinks: "Einbettungs-Links"
  },
  buttons: {
    fullscreen: "Vollbildmodus",
    popout: "Karte herauslösen",
    poisAndStreets: "Inhaltsverzeichnis",
    routing: "Routenplaner",
    close: "Schließen",
    gps: "Meine Position"
  },
  menuPopup: {
    back: "Zurück zu den Ergebnissen",
    pois: "Kategorien",
    streets: "Straßen"
  },
  poiDetails: {
    moreInfo: "Mehr Infos",
    openingHours: "Öffnungszeiten",
    opened: "Geöffnet",
    closed: "Geschlossen",
    showMore: "Mehr zeigen",
    showLess: "Weniger zeigen",
    altitude: "Höhe",
    zmin: "Min. Höhe",
    zmax: "Max. Höhe",
    distance: "Entfernung",
    time: "Zeit",
    content: "Inhalt",
    ytitle: "Entfernung",
    xtitle: "Höhe"
  },
  measureComponent: {
    colorSelector: "Farbe",
    shape: "Form",
    measure: "Messen",
    length: "Länge",
    area: "Fläche",
    radius: "Radius",
    circumference: "Umfang",
    delete: "Alle löschen"
  },
  rightClickFrame: {
    startPoint: "Startpunkt wählen",
    endPoint: "Endpunkt wählen",
    whatIsHere: "Was befindet sich hier?",
    print: "Drucken",
    measure: "Messen"
  },
  routeFrame: {
    routeOptions: "Routenoptionen",
    highway: "Autobahnen",
    tollRoad: "Mautstraßen",
    ferry: "Fähren",
    units: "Einheiten",
    startPoint: "Startpunkt",
    midPoint: "Zwischenziel",
    endPoint: "Endpunkt",
    metric: "Metrisch (km)",
    imperial: "Angloamerikanisch (mi)",
    via: "über",
    departure: "Abfahrt:",
    details: "Details",
    error: "Ein Fehler ist aufgetreten:",
    getDirections: "Los!",
    switchPoints: "Wegpunkte tauschen",
    close: "Routenplaner schließen",
    directions_car: "Mit dem Auto",
    directions_bus: "Mit öffentlichen Verkehrsmitteln",
    directions_bike: "Mit dem Fahrrad",
    directions_walk: "Zu Fuß"
  },
  streetEntry: {num: "Nr."},
  searchPlaceholder: "Suche ...",
  searchFrame: {
    previous: "zurück",
    next: "weiter",
    loading: "Laden",
    error: "Ein Fehler ist aufgetreten",
    noResults: "Keine Ergebnisse",
    pois: "POIs",
    streets: "Straßen",
    other: "Andere"
  },
  general: {
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag",
    saturday: "Samstag",
    sunday: "Sonntag",
    copy: "In die Zwischenablage kopieren"
  },
  printOverlay: {
    titlePlaceholder: "Titel (optional)",
    notesPlaceholder: "Notiz (optional)",
    print: "Drucken",
    close: "Schließen",
    pleaseWait: "Bitte warten Sie, während Ihr Ausdruck erstellt wird",
    pleaseWaitSubscript: "Dies kann mehrere Sekunden dauern."
  },
  gdpr: {
    title: "DSGVO Benachrichtigung",
    message:
      "Diese Seite verwendet anonymisierte Statistiken zur Verbesserung der Benutzererfahrung. " +
      "Mit 'Ok' bestätigen Sie, dass diese Auswertung durchgeführt werden darf.",
    ok: "Ok",
    no: "Nein danke"
  },
  wfs: {
    intro_description: "Bitte wählen Sie eine Element auf der Karte, um dessen Details anzuzeigen.",
    no_elem_found: "Es wurde kein Element an dieser Stelle gefunden.",
    unknown_error: "Ein unbekannter Fehler ist aufgetreten: "
  }
};
