<template>
  <div class="poi-wrapper">
    <div class="close-button" @click="goBack">
      <img src="../../assets/icons/cancel-24px.png" alt="close" />
    </div>
    <div id="poi-data" v-if="poiData" :class="{expanded: showMore}">
      <div class="cover-wrapper">
        <div class="cover" v-if="rotator.length > 0" :style="getCoverBackground()">
          <link rel="prefetch" as="image" v-for="img in rotator" :key="img" :href="hostAddress + img" />
          <img class="cover-img" :src="hostAddress + rotator[selected]" />
          <img class="cover-logo" v-if="poiData.logo" :src="hostAddress + poiData.logo" alt="logo" />
          <div class="arrowbox back" v-if="rotator.length > 1" @click="selected = (selected + 1) % rotator.length">
            <span class="material-icons-round">arrow_back_ios</span>
          </div>
          <div
            class="arrowbox forward"
            v-if="rotator.length > 1"
            @click="selected = (selected + rotator.length - 1) % rotator.length"
          >
            <span class="material-icons-round">arrow_forward_ios</span>
          </div>
        </div>
        <div class="poi-infos">
          <div class="poi-intro">
            <div class="poi-name">{{ poiData.name }}</div>
            <div class="poi-address" v-if="poiData.address">
              {{ poiData.address[0] }}
            </div>
            <div class="poi-address" v-if="poiData.address">
              {{ poiData.address[1] }}
            </div>
            <div class="is-open-info" :class="{open: isOpened, expanded: isDesktop() || showMore }" v-if="poiData.opening_hours">
              <i class="material-icons-round md-15 white">schedule</i>
              {{ isOpened ? $t("poiDetails.opened") : $t("poiDetails.closed") }}
            </div>
            <div class="poi-links" :class="{expanded: isDesktop() || showMore }">
              <div class="poi-link" v-if="poiData.contact.tel1">
                <a :href="'tel:' + poiData.contact.tel1">{{ poiData.contact.tel1 }}</a>
              </div>
              <div class="poi-link" v-if="poiData.contact.mail">
                <a :href="'mailto:' + poiData.contact.mail">{{ poiData.contact.mail }}</a>
              </div>
              <div class="poi-link" v-if="poiData.contact.website && !poiData.contact.website.includes('outdooractive')">
                <a :href="poiData.contact.website" target="_blank">{{ websiteDisplay }}</a>
              </div>
            </div>
            <div class="attribution-info" v-if="poiData.attribution">
              <span>{{ $t("poiDetails.content") }} &copy; </span>
              <span v-html="poiData.attribution"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="more-infos-wrapper">
        <div
          class="more-info"
          :class="{expanded: showMore}"
          v-show="poiData.opening_hours || poiData.long_info || elevations"
        >
          <div class="export-buttons" v-if="elevations">
            <a @click="exportTrack('gpx')">GPX</a>
            <a @click="exportTrack('geojson')">GeoJSON</a>
          </div>
          <div id="height-profile" />
          <div class="opening-schedule" v-if="poiData.opening_hours">
            <div class="time-info">{{ $t("poiDetails.openingHours") }}</div>
            <table class="opening-schedule">
              <tr class="working-days" v-for="day in weekDays" :key="day.id">
                <td>{{ $t("general." + day.toLowerCase()) }}</td>
                <td v-if="poiData.opening_hours[day].length > 0">
                  <div v-for="op in poiData.opening_hours[day]" :key="op[0]">{{ op[0] }} - {{ op[1] }}</div>
                </td>
                <td v-else>{{ $t("poiDetails.closed") }}</td>
              </tr>
            </table>
          </div>
          <div v-if="poiData.long_info">
            <div class="time-info">{{ $t("poiDetails.moreInfo") }}</div>
            <div class="additional-info" v-html="poiData.long_info"></div>
          </div>
        </div>
        <div
          v-if="poiData.opening_hours || poiData.long_info || elevations || poiData.contact.website || poiData.contact.tel1 || poiData.contact.mail"
          class="addit-info"
          @click="showMore = !showMore"
        >
          {{ showMore ? $t("poiDetails.showLess") : $t("poiDetails.showMore") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CategoryService from "@/services/category";
import M2wApi from "@/services/m2w_api";
import {basemap} from "@/services/map/basemap";
import {googleMaps} from "@/services/map/googleMaps";
import LineString from "ol/geom/LineString";
import Profil from "ol-ext/control/Profile";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import CircleStyle from "ol/style/Circle";
import Stroke from "ol/style/Stroke";
import GPX from "ol/format/GPX";
import GeoJSON from "ol/format/GeoJSON";

export default {
  name: "PoiDetails",
  //components: {Carousel, Slide},
  data() {
    return {
      weekDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      hostAddress: M2wApi.host_address,
      poiData: null,
      showMore: this.isDesktop(),
      prevRoute: null,
      elevations: null,
      coverLoaded: false,
      selected: 0
    };
  },
  computed: {
    isOpened: function() {
      return CategoryService.checkIfOpened(this.poiData);
    },
    openingHours: function() {
      return this.poiData.opening_hours;
    },
    parent: function() {
      return this.$route.query.parent;
    },
    websiteDisplay: function() {
      if (this.poiData.contact.web_label) return this.poiData.contact.web_label;

      let matches = this.poiData.contact.website.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
      if (matches) return matches[1];
      return "";
    },
    rotator: function() {
      let tmp = [...this.poiData.gallery];
      if (this.poiData.cover) tmp.unshift(this.poiData.cover);
      return tmp;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },

  watch: {
    "$route.params.id": async function(id, oldId) {
      if (id && parseInt(id) !== oldId)
        // compare string reprs as these are sometimes ints -.-
        await this.fetchFromApi();
    },

    "$i18n.locale": async function() {
      await this.fetchFromApi();
    }
  },

  async mounted() {
    await this.fetchFromApi();
  },

  deactivated() {
    this.$layers.teardownSinglePoi(this.poiData);
    this.poiData = null;
    this.cleanUpHeightProfile();
  },

  beforeDestroy() {
    this.$layers.teardownSinglePoi(this.poiData);
    this.poiData = null;
    this.cleanUpHeightProfile();
  },

  methods: {
    async fetchFromApi() {
      this.$layers.teardownSinglePoi(this.poiData);
      this.poiData = null;
      const id = this.$route.params.id;
      this.poiData = await M2wApi.get_single_poi(id, this.$i18n.locale, this.parent);
      await this.showOnMap();
      this.loadElevation();
    },

    async showOnMap() {
      let extent = await this.$layers.setupSinglePoi(this.poiData);
      basemap.map.getView().fit(extent, {duration: 500, minResolution: 1});
    },

    async goBack() {
      // preserve other query properties
      if (this.parent) {
        await this.$router.push({name: "pois", query: {...this.$route.query, "toc-id": this.parent}});
      } else if (this.prevRoute.name != null) {
        this.$router.go(-1);
      } else {
        await this.$router.push({name: "default"});
      }
    },

    loadElevation() {
      if (!this.poiData.geom.type.includes("LineString") || !this.poiData.parent.show_profile) {
        this.elevations = null;
        return;
      }

      let translations = {
        ...Profil.prototype.info,
        altitude: this.$t("poiDetails.altitude"),
        zmin: this.$t("poiDetails.zmin"),
        zmax: this.$t("poiDetails.zmax"),
        distance: this.$t("poiDetails.distance"),
        time: this.$t("poiDetails.time"),
        ytitle: this.$t("poiDetails.altitude") + " (m)",
        xtitle: this.$t("poiDetails.distance") + " (km)"
      };

      let that = this;
      function plotElevation(elevations, status) {
        if (status !== "OK") {
          // if we fail, let's just skip
          return;
        }

        that.elevations = elevations.map(e => {
          let transformed = googleMaps.transformFromLatLng(e.location);
          return [transformed[0], transformed[1], Math.round(e.elevation)]; // to XYZ array
        });

        // attempt: feature without layer
        let geometry = new LineString(that.elevations);
        that.cleanUpHeightProfile();

        that.profile = new Profil({
          target: document.querySelector("#height-profile"),
          style: new Style({
            fill: new Fill({color: "#ccc"})
          }),
          info: translations,
          width: 275,
          height: 190
        });
        basemap.map.addControl(that.profile);
        that.profile.setGeometry(geometry);

        // set up point that shows on hover
        let point = new Feature(new Point([0, 0]));
        point.setStyle([]);
        that.poiData.layer.getSource().addFeature(point);

        function drawPoint(e) {
          if (e.type === "over") {
            // Show point at coord
            point.setGeometry(new Point(e.coord));
            point.setStyle(
              new Style({
                image: new CircleStyle({
                  radius: that.poiData.style.line_thickness,
                  fill: new Fill({
                    color: basemap.toRgba(that.poiData.style.line_color, 0)
                  }),
                  stroke: new Stroke()
                })
              })
            );
          } else {
            // hide point
            point.setStyle([]);
          }
        }

        // Show a popup on over
        that.profile.on(["over", "out"], e => drawPoint(e));
      }

      let coordinates = this.poiData.geom.coordinates;
      if (this.poiData.geom.type === "MultiLineString") coordinates = Array.prototype.concat(...coordinates);

      let path = new LineString(coordinates);
      let factor = 0.5;
      while (path.getCoordinates().length > 500) {
        path = path.simplify(factor);
        factor *= 2;
      }
      //console.log(path.getCoordinates().length);

      let gpath = path.getCoordinates().map(c => googleMaps.transformToLatLngLiteral(c));
      let elevator = new window.google.maps.ElevationService();
      elevator.getElevationAlongPath({path: gpath, samples: 250}, plotElevation);
    },

    cleanUpHeightProfile() {
      let elem = document.querySelector("#height-profile");
      if (elem) elem.innerHTML = "";
    },

    exportTrack(format) {
      let coords = this.poiData.geom.coordinates;
      if (this.poiData.geom.type === "MultiLineString") coords = Array.prototype.concat(...coords);
      let ls = new LineString(coords);
      let feature = new Feature(ls.simplify(0.5));
      let outputString;
      let options = {
        featureProjection: "EPSG:3857"
      };

      if (format === "gpx") outputString = new GPX().writeFeatures([feature], options);
      else if (format === "geojson") outputString = new GeoJSON().writeFeatures([feature], options);
      else return;

      let element = document.createElement("a");
      element.setAttribute("href", "data:application/gpx+xml;charset=utf-8," + encodeURIComponent(outputString));
      if (format === "gpx") element.setAttribute("download", "track.gpx");
      else element.setAttribute("download", "track.geojson");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },

    // method for expanded description
    isDesktop() {
      if (screen.width >= 600) {
        return true;
      } else {
        return false;
      }
    },

    getCoverBackground() {
      if(this.poiData.style && this.poiData.style.fill_color) {
        return {'background-color': this.poiData.style.fill_color};
      }
      if(this.poiData.parent.style && this.poiData.parent.style.fill_color) {
        return {'background-color': this.poiData.parent.style.fill_color};
      }
      return '';
    }
  }
};
</script>
