<template>
  <div>
    <vue-progress-bar></vue-progress-bar>
    <div id="lhs_wrapper">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
    <div id="store-links" v-if="project.logo">
      <img class="logo-img" :src="hostAddress + project.logo" alt="" />
      <a v-if="project.cityapp" :href="project.cityapp.play_store_url" target="_blank">
        <i class="material-icons-round">android</i>
      </a>
      <a v-if="project.cityapp" :href="project.cityapp.app_store_url" target="_blank">
        <img src="../assets/icons/apple.png" alt="apple" />
      </a>
    </div>
    <div id="bottom-bar">
      <div id="company-links">
        <img class="company-logo" src="../assets/icons/sf_logo.png" alt="" />
        <ImprintLinks />
      </div>
      <div id="scale-bar"></div>
      <div id="imprint"></div>
    </div>

    <SettingButtonsComponent />
    <PrintOverlay v-if="$root.$data.printMode" />
    <div id="hover-popup"></div>
    <ul id="cluster-popup"></ul>
    <div id="map" @contextmenu.prevent="$refs.menu.open" @contextmenu="setCoordinates($event)"></div>
    <vue-context ref="menu" v-if="!$root.$data.embedded" :close-on-click="false" :lazy="true">
      <div class="coords">
        <p>{{ this.googleCoords }}</p>
        <i class="material-icons-round copy" @click.prevent="copyCoordinates">copy</i>
      </div>
      <li @click.prevent="setStartpoint">
        <img src="../assets/icons/marker.png" alt="" />
        <a>{{ $t("rightClickFrame.startPoint") }}</a>
      </li>
      <li @click.prevent="setEndpoint">
        <img src="../assets/icons/marker.png" alt="" />
        <a>{{ $t("rightClickFrame.endPoint") }}</a>
      </li>
      <li @click.prevent="getNearestPoi">
        <img src="../assets/icons/location.png" alt="" />
        <a>{{ $t("rightClickFrame.whatIsHere") }}</a>
      </li>
      <li @click.prevent="togglePrintMode">
        <img src="../assets/icons/print.png" alt="" />
        <a>{{ $t("rightClickFrame.print") }}</a>
      </li>
      <li @click.prevent="goToMeasure">
        <img src="../assets/icons/ruler-pencil.png" alt="" />
        <a>{{ $t("rightClickFrame.measure") }}</a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import Vue from "vue";
import VueContext from "vue-context";
import "vue-context/src/sass/vue-context.scss";
import {basemap} from "@/services/map/basemap";
import SettingButtonsComponent from "./button_controls/SettingButtons";
import M2wApi from "@/services/m2w_api";
import PrintOverlay from "@/components/PrintOverlay";
import {googleMaps} from "@/services/map/googleMaps";
import ImprintLinks from "@/components/page_elements/ImprintLinks";
import ZIndex from "@/services/zindex";
import Clipboard from "@/services/clipboard";

export default {
  name: "MapComponent",
  components: {ImprintLinks, PrintOverlay, VueContext, SettingButtonsComponent},
  data() {
    return {
      map: {},
      coordinates: null,
      category: {},
      hostAddress: M2wApi.host_address,
      project: Vue.config.project
    };
  },
  computed: {
    googleCoords: function() {
      if (this.coordinates) return googleMaps.toGoogleCoordinateString(this.coordinates);
      return null;
    }
  },

  async mounted() {
    await basemap.initMap(this.$route.query["lid"]);

    if (Vue.config.project.sf_basemap) {
      try {
        let response = "";
        if (Vue.config.project.sf_basemap.type == "wmts") {
          response = await basemap.getWmtsLayer(Vue.config.project.sf_basemap);
        } else if (Vue.config.project.sf_basemap.type == "wms") {
          response = await basemap.getWmsLayer(Vue.config.project.sf_basemap);
        } else if (Vue.config.project.sf_basemap.type == "xyz") {
          response = await basemap.getXyzLayer(Vue.config.project.sf_basemap);
        }
        response.setZIndex(ZIndex.SFMap);
        Vue.config.project.sf_basemap.layer = response;
        basemap.map.addLayer(response);
      } catch (e) {
        console.log(e);
      }
    }

    if (this.$route.path === "/" && Vue.config.project.default_view !== "/") {
      await this.$router.push(Vue.config.project.default_view);
    }

    // restore location from URL
    this.fetchLocationUrl();
    window.addEventListener("popstate", this.fetchLocationUrl);
    basemap.map.on("moveend", this.updatePermalink);
  },

  methods: {
    togglePrintMode() {
      this.$root.$data.printMode = !this.$root.$data.printMode;
      this.$refs.menu.close();
    },

    setCoordinates(event) {
      this.coordinates = basemap.map.getEventCoordinate(event);
    },

    async copyCoordinates() {
      await Clipboard.copy(this.googleCoords);
    },

    async getNearestPoi() {
      let closest = await M2wApi.get_closest_poi(this.coordinates, this.$i18n.locale);
      if (closest.obj) {
        await this.$router.push({name: "poiDetails", params: {id: closest.obj}, query: this.$route.query});
      } else if (closest.address) {
        await this.$router.push({
          name: "pois",
          params: {view: "streets"},
          query: {
            ...this.$route.query,
            str_id: closest.address.id,
            hnr: closest.address.house_number.hnr
          }
        });
      }
      this.$refs.menu.close();
    },

    goToMeasure() {
      this.$router.push({name: "measure"});
      this.$refs.menu.close();
    },

    setStartpoint() {
      this.$router.push({name: "directions", query: {...this.$route.query, from: this.googleCoords}});
      this.$refs.menu.close();
    },

    setEndpoint() {
      this.$router.push({name: "directions", query: {...this.$route.query, to: this.googleCoords}});
      this.$refs.menu.close();
    },

    updatePermalink() {
      if (!this.shouldUpdate) {
        // do not update the URL when the view was changed in the 'popstate' handler
        this.shouldUpdate = true;
        return;
      }
      const view = basemap.map.getView();
      const center = view.getCenter();
      const serialized = Math.round(center[0]) + "," + Math.round(center[1]) + "," + view.getZoom();

      // only push on actual location change, relevant when navigating back
      if (this.$route.query.location !== serialized)
        this.$router.replace({query: {...this.$route.query, location: serialized}});
    },

    fetchLocationUrl() {
      const locationString = this.$route.query.location;
      let center;
      let zoom;
      if (!locationString) return;

      let locationStringRegex = /^([-0-9.]+),([-0-9.]+),([0-9.]+)$/;
      let matches = locationString.match(locationStringRegex);
      if (matches) {
        center = [parseFloat(matches[1]), parseFloat(matches[2])];
        zoom = parseFloat(matches[3]);

        const view = basemap.map.getView();

        view.setCenter(center);
        view.setZoom(zoom);
        this.map = basemap.map;
      }

      if (Vue.config.project.sf_basemap) {
        if (this.$route.query["sflo"] && !isNaN(this.$route.query["sflo"])) {
          let parsed = parseInt(this.$route.query["sflo"], 10);
          if (parsed >= 0 && parsed <= 100) {
            Vue.config.project.sf_basemap.layer.setOpacity(parsed / 100);
          }
        }
      }
    }
  }
};
</script>
