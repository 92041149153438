<template>
  <div id="app" :class="{
    'print-mode': $root.$data.printMode,
    'force-mobile': forceMobile,
    'force-desktop': forceDesktop,
    'embedded': $root.$data.embedded
  }">
    <GdprPopup />
    <router-view />
  </div>
</template>

<script>
import GdprPopup from "./components/GdprPopup";
export default {
  components: {GdprPopup},
  data() {
    return {
      forceMobile: false,
      forceDesktop: false
    };
  },
  created() {
    let mobile = this.$route.query["mobile"];
    if (mobile) {
      let parsed = mobile.toLowerCase() === "true";
      this.forceMobile = parsed;
      this.forceDesktop = !parsed;
    }

    let embedded = this.$route.query["embedded"];
    this.$root.$data.embedded = !!embedded && embedded.toLowerCase() === "true";
  },
  watch: {
    '$i18n.locale': function(locale){
      document.querySelector('html').setAttribute('lang', locale)
    }
  }
};
</script>

<style lang="scss">
@import "assets/css/reset.css";
@import "~ol/ol.css";
@import "~ol-ext/control/Profil.css";

@import "assets/css/icons";
@import "assets/css/basemap";
@import "assets/css/bottom-bar";
@import "assets/css/menu-popup";
@import "assets/css/search-frame";
@import "assets/css/menu-component";
@import "assets/css/category-frame";
@import "assets/css/control-popup";
@import "assets/css/single-poi";
@import "assets/css/poi-data";
@import "assets/css/streets-popup";
@import "assets/css/right-click-frame";
@import "assets/css/measure-frame";
@import "assets/css/routing/main";
@import "assets/css/routing/drive-options";
@import "assets/css/routing/steps-frame";
@import "assets/css/settings-frame";
@import "assets/css/startup";
@import "assets/css/legend";
@import "assets/css/print";
</style>
