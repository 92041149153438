<template>
  <div>
    <div id="category-wrapper" v-if="currentCategory && currentCategory.type !== 'wfs_folder'">
      <!-- chosen categories header -->
      <div id="category-header" v-if="currentCategory.parent">
        <button id="arrow" @click="chooseCategory(currentCategory.parent)">
          <i v-if="!$root.$data.embedded" class="material-icons-round">arrow_back_ios</i>
          {{ currentCategory.name }}
          <span v-if="collapsed && !$root.$data.embedded">({{ childCount(currentCategory) }})</span>
        </button>
        <div class="actions">
          <div v-if="hasItems && !$root.$data.embedded" class="pin" @click="togglePin(currentCategory)">
            <i class="material-icons-round" :class="{pinned: isPinned}" title="pin">push_pin</i>
          </div>
          <div class="collapse" @click="collapsed = !collapsed">
            <i class="material-icons-round" :class="{collapsed: collapsed}" title="collapse">keyboard_arrow_down</i>
          </div>
        </div>
      </div>
      <div id="chosen-category" v-if="pinnedCategories.length > 0">
        <div v-for="category in pinnedCategories" :key="category.id" class="chosen-single-cat">
          <div class="chosen-inner-cat">
            <div class="chosen-category-name">{{ category.name }} ({{ childCount(category) }})</div>
            <a class="close-button" @click="togglePin(category)" v-if="!$root.$data.embedded">Remove</a>
          </div>
        </div>
      </div>
      <div class="category-content" v-if="!collapsed">
        <div v-for="item in [...currentCategory.children, ...pinnedChildren]" :key="item.id" class="category-container">
          <!-- don't show sub-folders on embedded -->
          <button v-if="['folder', 'wfs_folder'].includes(item.type) && !$root.$data.embedded" class="category" @click="chooseCategory(item)" :style="getStyle(item)">
            <img v-if="item.image" :src="hostAddress + item.image" alt="" />
            <img v-else src="/images/placeholder.png" alt="" />
            <div class="category-box">
              <img v-if="item.logo" :src="hostAddress + item.logo" alt="" />
              <div class="category-name" v-html="item.name"></div>
            </div>
          </button>
          <PoiTocCard v-if="item.type === 'item' && item.name" :item="item" />
        </div>
      </div>
    </div>
    <WfsFolder v-if="currentCategory && currentCategory.type === 'wfs_folder'" v-bind:category="currentCategory" @return="chooseCategory(currentCategory.parent)"></WfsFolder>
  </div>
</template>
<script>
import PoiTocCard from "./PoiTocCard";
import M2wApi from "@/services/m2w_api";
import WfsFolder from "@/components/category_components/WfsFolder";

export default {
  name: "CategoriesFrame",
  components: {WfsFolder, PoiTocCard},
  data() {
    return {
      hostAddress: M2wApi.host_address,
      categoryTree: null,
      currentCategory: null,
      pinnedCategories: [],
      collapsed: this.$root.$data.embedded
    };
  },

  async mounted() {
    await this.fetchFromApi();
  },
  activated() {
    // activated is also called on mounted (without waiting for async)
    // so this check prevents activated from firing when mounted is already underway
    if (this.categoryTree) {
      this.onActivated();
    }
  },
  deactivated() {
    if (this.currentCategory) this.$layers.teardownCategory(this.currentCategory);
    for (let cat of this.pinnedCategories) this.$layers.teardownCategory(cat);
  },

  computed: {
    pinnedChildren: function() {
      let distinctChildren = this.distinct(
        this.pinnedCategories
          .map(cat => cat.children)
          .flat()
          .filter(child => child.type === "item"),
        item => item.id
      );

      // only return items that are not children of the current category (as these are displayed anyhow)
      return distinctChildren.filter(c => !this.currentCategory.children.find(cc => c.id === cc.id));
    },
    hasItems: function() {
      return this.currentCategory.children.some(child => child.type === "item");
    },
    isPinned: function() {
      return this.pinnedCategories.includes(this.currentCategory);
    }
  },

  watch: {
    pinnedCategories: async function(newValues, oldValues) {
      for (let cat of oldValues) {
        if (cat !== this.currentCategory && !newValues.includes(cat)) {
          this.$layers.teardownCategory(cat);
        }
      }

      for (let cat of newValues) {
        if (oldValues.includes(cat)) continue;

        await this.$layers.setupCategory(cat, this.goToPoi);
      }
      let queryValue = newValues.map(cat => cat.id).join(",");
      if (this.$route.query.pinned !== queryValue) {
        await this.$router.push({query: {...this.$route.query, pinned: queryValue}});
      }
    },

    // reload data on language change
    "$i18n.locale": async function() {
      await this.fetchFromApi();
    }
  },

  methods: {
    async fetchFromApi() {
      this.$Progress.start();
      this.categoryTree = await M2wApi.get_project_pois(this.$i18n.locale);
      // walking through the category tree to add back links
      this.linkChildren(this.categoryTree);
      await this.onActivated();
      this.$Progress.finish();
    },

    async chooseCategory(category) {
      // on embedded we do not allow folder navigation
      if (this.$root.$data.embedded) return;

      this.collapsed = false;
      // if the name actually is a link, open that link *facepalm*
      if (category.name && category.name.includes("href=")) {
        let href = category.name.match(/href="(.+?)"/)[1];
        window.open(href);
      }
      // wfs layer specialized view
      // category without children is probably an overlay, unless it got WFS
      else if (category.children.length === 0 && category.type !== "wfs_folder") {
        this.togglePin(category);
      } else {
        await this.updateCurrentCategory(category, this.currentCategory);
      }
    },

    async updateCurrentCategory(category, oldCategory) {
      this.currentCategory = category;

      if (oldCategory && !this.pinnedCategories.includes(oldCategory)) {
        this.$layers.teardownCategory(oldCategory);
      }

      await this.$layers.setupCategory(category, this.goToPoi);

      this.$emit("showHeader", category.name === null);

      // update URL for deep linking
      if (!category.id && this.$route.query["toc-id"]) {
        const {["toc-id"]: _, ...filtered} = this.$route.query;
        await this.$router.push({query: filtered});
      }
      if (
        category.id &&
        (!this.$route.query["toc-id"] || this.$route.query["toc-id"].toString() !== category.id.toString())
      )
        await this.$router.push({query: {...this.$route.query, "toc-id": category.id}});
    },

    async onActivated() {
      this.currentCategory = this.findCurrentCategory();
      await this.updateCurrentCategory(this.currentCategory, null);

      this.pinnedCategories = this.findPinnedCategories();
    },

    togglePin(category) {
      // if the category is already in the collection, bail early
      if (this.pinnedCategories.includes(category)) {
        this.pinnedCategories = this.pinnedCategories.filter(c => c !== category);
      } else {
        this.pinnedCategories = [...this.pinnedCategories, category];
      }
    },

    linkChildren(category) {
      if (category.children) {
        for (let child of category.children) {
          child["parent"] = category;
          this.linkChildren(child);
        }
      }
    },

    findCurrentCategory() {
      let catId = this.$route.query["toc-id"];
      if (catId && !isNaN(catId)) {
        let cat = this.findCategory(this.categoryTree, parseInt(catId));
        if (cat) return cat;
      }
      // no (valid) catId -> root node
      return this.categoryTree;
    },

    findCategory(node, catId) {
      if (node.id === catId) return node;
      if (node.children) {
        for (let child of node.children) {
          let hit = this.findCategory(child, catId);
          if (hit) return hit;
        }
      }
      return null;
    },

    findPinnedCategories() {
      let catIds = this.$route.query["pinned"];
      if (!catIds) return [];

      let split = catIds.split(",");
      return split
        .map(chunk => chunk.trim())
        .filter(chunk => !isNaN(chunk))
        .map(chunk => parseInt(chunk))
        .filter((v, i, a) => a.indexOf(v) === i) // distinct
        .map(id => this.findCategory(this.categoryTree, id));
    },

    distinct(arr, func) {
      let tmp = {};
      return arr.filter(function(entry) {
        let key = func(entry);
        if (tmp[key]) {
          return false;
        }
        tmp[key] = true;
        return true;
      });
    },

    async goToPoi(poi) {
      if (poi.name)
        // ex. bus lines should not be clickable
        await this.$router.push({
          name: "poiDetails",
          params: {id: poi.id},
          query: {...this.$route.query, parent: poi.parent.id}
        });
    },

    childCount(cat) {
      return cat.children.filter(c => c.type === "item").length;
    },

    getStyle(category) {
      if (category.style && category.style.fill_color) {
        return {background: category.style.fill_color}
      }
      return '';
    }
  }
};
</script>
