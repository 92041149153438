<template>
  <button
    class="material-icons-round md-24"
    @click="toggleFullscreen($event)"
    :title="$t('buttons.fullscreen')"
  >
    {{ enabled ? "fullscreen_exit" : "fullscreen" }}
  </button>
</template>

<script>
export default {
  data: function() {
    return {
      enabled: false
    };
  },
  methods: {
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        this.enabled = true;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          this.enabled = false;
        }
      }
    }
  }
};
</script>
