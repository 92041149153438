<template>
  <div>
    <div id="directions-frame" v-if="!hideHeader">
      <div class="search-box">
        <button @click="goToMenu" class="btn lhs" :title="$t('buttons.poisAndStreets')">
          <img src="../../assets/icons/menu.png" alt="" />
        </button>
        <img class="line-icon" src="../../assets/icons/line.png" alt="" />
        <input id="from" :placeholder="$t('routeFrame.startPoint')" v-model="from" />
        <button class="btn rhs" @click="closeDirectionsFrame" :title="$t('routeFrame.close')">
          <img src="../../assets/icons/close.png" alt="" />
        </button>
        <img class="line-icon right" src="../../assets/icons/line.png" alt="" />
      </div>
      <div class="interleave">
        <!--<button @click="addWaypointAt(1)">+</button>-->
        <div class="hline"></div>
        <button @click="reversePoints()" :title="$t('routeFrame.switchPoints')">
          <i class="material-icons-round md-18">swap_vert</i>
        </button>
      </div>
      <div class="search-box">
        <button
          class="settings-icon btn lhs"
          :class="{active: showOptions}"
          @click="toggleRouteOptions()"
          :title="$t('routeFrame.routeOptions')"
        >
          <i class="material-icons-round">settings</i>
        </button>
        <input id="to" :placeholder="$t('routeFrame.endPoint')" v-model="to" />
        <button class="btn rhs" @click="fetchRoute" :title="$t('routeFrame.getDirections')">
          <i class="material-icons-round">directions</i>
        </button>
      </div>
      <RouteOptions v-if="showOptions" :options="routeOptions" />
    </div>
    <DirectionDisplay
      v-if="routes.length"
      :routes="routes"
      @changeDrivingMode="changeMode"
      @routeSelected="routeSelected"
    />
  </div>
</template>
<script>
import RouteOptions from "./RouteOptions";
import DirectionDisplay from "./DirectionDisplay";
import Vue from "vue";
import {getTransform} from "ol/proj";

export default {
  components: {RouteOptions, DirectionDisplay},
  data() {
    return {
      from: null,
      to: null,
      showOptions: false,
      hideHeader: false,
      routeOptions: {
        highways: true,
        ferries: true,
        tolls: true,
        units: "metric",
        mode: "DRIVING"
      },
      routes: [],
      directionsService: null,
      bounds: null
    };
  },

  beforeRouteLeave(to, from, next) {
    let query = from.query;

    if (query["from"] || query["to"]) {
      delete query["from"];
      delete query["to"];
      next({...to, query: query});
    } else {
      next();
    }
  },

  mounted() {
    const gm = window.google.maps;
    this.directionsService = new gm.DirectionsService();
    let transform = getTransform("EPSG:3857", "EPSG:4326");
    let coord = transform(Vue.config.project.center);
    this.bounds = new gm.LatLngBounds(new gm.LatLng(coord[1], coord[0]));
  },

  activated() {
    this.from = this.$route.query["from"];
    this.to = this.$route.query["to"];

    this.setupAutocomplete("from");
    this.setupAutocomplete("to");
  },

  deactivated() {
    this.routes = [];
  },

  watch: {
    from: async function(val) {
      await this.$router.push({query: {...this.$route.query, from: val}});
    },
    to: async function(val) {
      await this.$router.push({query: {...this.$route.query, to: val}});
    },

    "$route.query.from": function(val) {
      this.from = val;
      //console.log("pong");
    },
    "$route.query.to": function(val) {
      this.to = val;
    }
  },

  methods: {
    toggleRouteOptions() {
      this.showOptions = !this.showOptions;
    },

    routeSelected(selected) {
      this.hideHeader = selected;
    },

    reversePoints() {
      let tmp = this.from;
      this.from = this.to;
      this.to = tmp;
    },

    async closeDirectionsFrame() {
      await this.$router.push({name: "default"});
    },

    setupAutocomplete(input_id) {
      const gm = window.google.maps.places;
      let elem = document.getElementById(input_id);
      let ac = new gm.Autocomplete(elem);
      ac.setBounds(this.bounds);

      ac.addListener("place_changed", async () => {
        this[input_id] = elem.value;
      });
    },

    async fetchRoute() {
      let request = {
        origin: this.from,
        destination: this.to,
        travelMode: this.routeOptions.mode,
        avoidFerries: !this.routeOptions.ferries,
        avoidHighways: !this.routeOptions.highways,
        avoidTolls: !this.routeOptions.tolls,
        unitSystem:
          this.routeOptions.units === "metric"
            ? window.google.maps.UnitSystem.METRIC
            : window.google.maps.UnitSystem.IMPERIAL,
        provideRouteAlternatives: true
      };

      let apiPromise = new Promise(resolve => {
        this.directionsService.route(request, response => resolve(response));
      });

      let response = await apiPromise;
      if (response.status === "OK") {
        this.routes = response.routes;
        this.message = null;
      } else this.message = this.$t("routeFrame.error") + " " + response.status;
    },

    async goToMenu() {
      await this.$router.push({name: "pois", query: this.$route.query});
    },

    async changeMode(mode) {
      this.routeOptions.mode = mode;
      await this.fetchRoute();
    }
  }
};
</script>
