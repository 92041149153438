import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./services/i18n";
import vueDebounce from "vue-debounce";
import M2wApi from "./services/m2w_api";
import VueCookie from "vue-cookie";
import VueProgressBar from "vue-progressbar";
import VueGtag from "vue-gtag";
import PoiLayers from "./services/map/poiLayers";

M2wApi.get_project_info()
  .then(response => {
    // encountered an error
    if(response.message) {
      document.getElementById("app").innerHTML = "" +
        "<div class='startupError'>" +
        "<h2>Sorry, we encountered an error during startup</h2>" +
        "<p>The message given was: "+response.message+"</p></div>";
      return;
    }

    Vue.config.project = response;

    // update page title and meta data
    document.title = response.name + " - Map2Web";

    function updateMetaInfo(key) {
      if (response.meta[key] !== "")
        document.querySelector(`meta[name="${key}"]`).setAttribute("content", response.meta[key]);
    }

    updateMetaInfo("description");
    updateMetaInfo("author");
    updateMetaInfo("keywords");

    // start setting up Vue
    Vue.config.productionTip = false;
    Vue.use(new PoiLayers());
    Vue.use(vueDebounce);
    Vue.use(VueCookie);
    Vue.use(VueProgressBar);
    Vue.use(VueGtag, {
      config: {id: Vue.config.project.ga_tracking_id},
      bootstrap: false
    }, router);

    // use proper default language
    const languages = Vue.config.project.languages;
    const defaultLanguage = languages.find(l => l.is_default).abbreviation;
    const cookieLang = VueCookie.get("locale");
    const browserLang = navigator.language.substring(0, 2);
    const supportedLangs = languages.map(l => l.abbreviation);
    let lang;
    if (supportedLangs.includes(cookieLang)) {
      lang = cookieLang;
    } else if (supportedLangs.includes(browserLang)) {
      lang = browserLang;
    } else {
      lang = defaultLanguage;
    }
    i18n.locale = lang;
    document.querySelector('html').setAttribute('lang', lang)

    // finally build the app
    let app = new Vue({
      i18n,
      router,
      render: h => h(App),
      data: {
        printMode: false
      }
    });

    app.$mount("#app");
  });
