import {getTransform} from "ol/proj";

class GoogleMaps {
  googleToOlTransform = getTransform("EPSG:4326", "EPSG:3857");
  olToGoogleTransform = getTransform("EPSG:3857", "EPSG:4326");

  transformFromLatLng(coord) {
    return this.googleToOlTransform([this._getVal(coord.lng), this._getVal(coord.lat)]);
  }

  transformToLatLngLiteral(coord) {
    let transformed = this.olToGoogleTransform(coord);
    return {lat: transformed[1], lng: transformed[0]};
  }

  toGoogleCoordinateString(coord) {
    let coordinates = googleMaps.olToGoogleTransform(coord);
    return coordinates[1].toFixed(5) + ", " + coordinates[0].toFixed(5);
  }

  // read from variable or function
  _getVal(obj) {
    if(obj && obj.constructor && obj.call && obj.apply)
      return obj();

    return obj;
  }
}

export const googleMaps = new GoogleMaps();
