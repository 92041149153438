<template>
  <div>
    <div id="menu-frame" class="search-box" v-if="!$root.$data.embedded">
      <button class="btn lhs" :class="{active: isListsEnabled}" @click="toggleFrame()" :title="$t('buttons.poisAndStreets')">
        <img src="../assets/icons/menu.png" alt="" />
      </button>
      <img class="line-icon left" src="../assets/icons/line.png" alt="" />
      <form action="/" @submit.prevent>
        <input type="text" :placeholder="$t('searchPlaceholder')" v-debounce:500ms="submit" v-model="searchTerm" />
      </form>
      <button class="close-button" :class="{active: !!searchTerm}" @click="clearSearch()">
        <img src="../assets/icons/close1.png" alt="" />
      </button>

      <button class="btn rhs" @click="openDirectionFrame()" :title="$t('buttons.routing')">
        <i class="material-icons-round">directions</i>
      </button>
      <img class="line-icon right" src="../assets/icons/line.png" alt="" />
    </div>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>
<script>
import {basemap} from "@/services/map/basemap";

export default {
  data() {
    return {
      searchTerm: null,
      activeRouteFrame: false
    };
  },
  computed: {
    isListsEnabled: function() {
      return this.$route.path.indexOf("lists") >= 0;
    }
  },

  created() {
    this.searchTerm = this.$route.query["q"];
  },

  methods: {
    async toggleFrame() {
      if (this.isListsEnabled) {
        await this.$router.push({name: "default", query: this.$route.query});
      } else {
        await this.$router.push({name: "pois", query: this.$route.query});
      }
    },
    async submit() {
      await this.search(this.searchTerm);
    },

    async search(term) {
      if ((!term || !term.trim()) && this.$route.name === "search") {
        await this.$router.push({name: "default", query: this.$route.query});
        return;
      }

      const view = basemap.map.getView();
      const center = view.getCenter();
      const serialized = center[0] + "," + center[1] + "," + view.getZoom();

      await this.$router.push({
        name: "search",
        query: {
          ...this.$route.query,
          q: term,
          page: 0,
          location: serialized
        }
      });
    },

    async clearSearch() {
      this.searchTerm = "";
      if (this.$route.name === "search") {
        await this.$router.push({name: "default", query: this.$route.query});
      }
    },
    async openDirectionFrame() {
      await this.$router.push({name: "directions", query: this.$route.query});
    }
  }
};
</script>
