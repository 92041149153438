<template>
  <div class="print-overlay">
    <div class="waiting-mask" v-if="generating">
      <div class="text">
        <h1>{{ $t("printOverlay.pleaseWait") }}</h1>
        <h2>{{ $t("printOverlay.pleaseWaitSubscript") }}</h2>
      </div>
    </div>
    <div class="page-wrapper">
      <div id="page-border">
        <div class="borders">
          <div id="map-area">
            <img src="../assets/images/sf_logo.png" alt="" class="company-logo" />
            <img id="qrcode"  class="print-qr" />
          </div>
        </div>
        <div class="top-row">
          <input class="title" :placeholder="$t('printOverlay.titlePlaceholder')" v-model="title" />
          <img id="logo-img" :src="hostAddress + project.logo" alt="" crossorigin="anonymous" />
        </div>
        <div class="bottom-row">
          <div class="copyright">&copy; <strong>Schubert & Franzke</strong>, {{ new Date().getFullYear() }}</div>
          <div id="attribution"></div>
          <textarea class="notes" :placeholder="$t('printOverlay.notesPlaceholder')" v-model="notes" />
          <div id="print-scale-bar"></div>
        </div>
      </div>
    </div>
    <div class="button-wrapper">
      <div class="buttons">
        <button id="close" @click="closeFrame">{{ $t("printOverlay.close") }}</button>
        <button id="print" @click="createPrint">{{ $t("printOverlay.print") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import M2wApi from "@/services/m2w_api";
import {ScaleLine} from "ol/control";
import {basemap} from "@/services/map/basemap";
import printJS from "print-js";
import jsPDF from "jspdf";
import QRCode from "qrcode";

export default {
  name: "PrintOverlay",
  data: function() {
    return {
      project: Vue.config.project,
      hostAddress: M2wApi.host_address,
      title: null,
      notes: null,
      generating: false,
      qrcode: null
    };
  },
  mounted() {
    this.scaleLine = new ScaleLine({
      units: "metric",
      bar: true,
      steps: 4,
      text: false,
      target: document.getElementById("print-scale-bar"),
      minWidth: 200
    });
    basemap.map.addControl(this.scaleLine);

    let attributions = [...document.querySelectorAll(".ol-attribution li")];

    document.getElementById("attribution").innerText = attributions.map(a => a.innerText).join(" | ");
    this.updateQR();
  },
  destroyed() {
    basemap.map.removeControl(this.scaleLine);
  },
  watch: {
    $route() {
      this.updateQR();
    }
  },
  methods: {
    closeFrame() {
      this.$root.$data.printMode = false;
    },

    updateQR() {
      const image = document.getElementById('qrcode');
      QRCode.toDataURL(window.location.href, {
        errorCorrectionLevel: 'M',
        margin: 0
      }, function(err, url) {
        image.src = url;
      });
    },

    async createPrint() {
      this.generating = true;
      try {
        let pageSize = [297, 210]; // a4
        let resolution = 200; // dpi
        let pageWidth = Math.round((pageSize[0] * resolution) / 25.4);
        let pageHeight = Math.round((pageSize[1] * resolution) / 25.4);
        let pageRect = document.getElementById("map-area").getBoundingClientRect();
        let pageCenter = basemap.map.getCoordinateFromPixel([
          pageRect.x + pageRect.width / 2,
          pageRect.y + pageRect.height / 2
        ]);

        // renders the whole map of the viewport
        let mapCanvas = await this.renderMap(pageWidth, pageHeight, pageCenter);
        let context = mapCanvas.getContext("2d");

        let widthRatio = pageRect.width / basemap.map.getSize()[0];
        let desiredWidth = mapCanvas.width * widthRatio;
        let desiredHeight = (desiredWidth / pageRect.width) * pageRect.height;

        // TODO: the cut out print ratio is slightly too small!
        let imgData = context.getImageData(
          Math.round((mapCanvas.width - desiredWidth) / 2),
          Math.round((mapCanvas.height - desiredHeight) / 2),
          Math.round(desiredWidth),
          Math.round(desiredHeight)
        );

        let imageUrl = this.getImageURL(imgData, desiredWidth, desiredHeight);
        let pdf = this.generateBase64Pdf(imageUrl);

        printJS({
          printable: pdf,
          type: "pdf",
          base64: true
        });
      } finally {
        this.generating = false;
      }
    },

    renderMap(pageWidth, pageHeight, pageCenter) {
      // values for resetting afterwards
      let mapSize = basemap.map.getSize();
      let viewResolution = basemap.map.getView().getResolution();
      let viewCenter = basemap.map.getView().getCenter();

      let promise = new Promise(resolve => {
        basemap.map.once("rendercomplete", function() {
          let targetCanvas = document.createElement("canvas");
          targetCanvas.width = pageWidth;
          targetCanvas.height = pageHeight;
          const canvasContext = targetCanvas.getContext("2d");

          document.querySelectorAll(".ol-layer canvas").forEach(function(canvas) {
            if (canvas.width > 0) {
              const opacity = canvas.parentNode.style.opacity;
              canvasContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
              const transform = canvas.style.transform;
              // Get the transform parameters from the style's transform matrix
              const matrix = transform
                .match(/^matrix\(([^(]*)\)$/)[1]
                .split(",")
                .map(Number);
              // Apply the transform to the export map context
              canvasContext.setTransform(matrix);
              canvasContext.drawImage(canvas, 0, 0);
            }
          });
          // TODO: we need to get these overlays into the rendered image
          // document.querySelectorAll('.ol-overlay-container').forEach(function(overlay){})

          // Reset original map size
          basemap.map.setSize(mapSize);
          basemap.map.getView().setResolution(viewResolution);
          basemap.map.getView().setCenter(viewCenter);
          resolve(targetCanvas);
        });
      });

      // Set print size
      basemap.map.setSize([pageWidth, pageHeight]);
      const scaling = Math.min(pageWidth / mapSize[0], pageHeight / mapSize[1]);
      basemap.map.getView().setResolution(viewResolution / scaling);
      basemap.map.getView().setCenter(pageCenter);

      return promise;
    },

    getImageURL(imgData, width, height) {
      let canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      if (imgData instanceof HTMLImageElement) ctx.drawImage(imgData, 0, 0);
      else ctx.putImageData(imgData, 0, 0);

      return canvas.toDataURL();
    },

    generateBase64Pdf(imageUrl) {
      let doc = new jsPDF("landscape");
      doc.addFont("/fonts/Quicksand-Regular.ttf", "Quicksand", "normal");
      doc.setFont("Quicksand");

      //city logo
      let logoElement = document.getElementById("logo-img");

      let vh_in_px = logoElement.clientHeight / 5; // we know this element is 5 high, so we can extrapolate 1vh
      let vh_to_mm = 2.5; // 1vh is 2.5mm
      let px_to_mm = vh_to_mm / vh_in_px;

      let logoWidth = logoElement.clientWidth * px_to_mm;
      let logoX = 297 - 5 * vh_to_mm - logoWidth;
      //let convertedLogo = this.getImageURL(logoElement, logoElement.naturalWidth, logoElement.naturalHeight)
      doc.addImage(logoElement, "JPEG", logoX, 2.5 * vh_to_mm, logoWidth, 5 * vh_to_mm);

      // map image
      // 297mm = 120vh -> 5vh ~= 12.5mm, 10 vh ~= 25mm
      doc.addImage(imageUrl, 5 * vh_to_mm, 10 * vh_to_mm, 297 - 10 * vh_to_mm, 210 - 20 * vh_to_mm);
      doc.rect(5 * vh_to_mm, 10 * vh_to_mm, 297 - 10 * vh_to_mm, 210 - 20 * vh_to_mm);
      // sf-logo-overlay
      let sfLogo = document.querySelector("#map-area .company-logo");
      doc.addImage(
        sfLogo,
        5.1 * vh_to_mm,
        210 - 10.1 * vh_to_mm - sfLogo.clientHeight * px_to_mm,
        sfLogo.clientWidth * px_to_mm,
        sfLogo.clientHeight * px_to_mm
      );

      let qrcode = document.querySelector("#qrcode");
      const border = .3*vh_to_mm;
      const qr_width = qrcode.clientWidth * px_to_mm;
      const qr_height = qrcode.clientHeight * px_to_mm;
      const qr_xpos = 297 - 5 * vh_to_mm - qr_width;
      const qr_ypos = 210 - 10 * vh_to_mm - qr_height;

      // background
      doc.setFillColor("#FFFFFF");
      doc.rect(qr_xpos - 2 * border, qr_ypos - 2 * border, qr_width + 2 * border, qr_height + 2 * border, "FD");
      doc.addImage(qrcode, qr_xpos - border, qr_ypos - border, qr_width, qr_height);

      // title and notes
      if (this.title) {
        doc.setFontSize(17);
        doc.text(this.title, 5 * vh_to_mm, 5 * vh_to_mm, {baseline: "middle"});
      }
      if (this.notes) {
        doc.setFontSize(10);
        doc.text(this.notes, 5 * vh_to_mm, 210 - 5 * vh_to_mm, {baseline: "middle", maxWidth: 297 / 2});
      }

      // emulate scale bar
      let bar = document.getElementById("print-scale-bar").firstElementChild;
      let barWidth = bar.clientWidth * px_to_mm;
      let barHeight = 10 * px_to_mm;
      let barX = 297 - 5 * vh_to_mm - barWidth;
      let singleBarWidth = barWidth / 4;
      let barY = 210 - 5 * vh_to_mm - barHeight;
      for (let i = 0; i < 4; i++) {
        let x = barX + singleBarWidth * i;
        doc.rect(x, barY, singleBarWidth, barHeight, i % 2 ? "DF" : "S");
        doc.line(x, barY, x, barY + 15 * px_to_mm);
      }
      // last line
      doc.line(barX + barWidth, barY, barX + barWidth, barY + 15 * px_to_mm);
      let stepTexts = document.querySelectorAll("#print-scale-bar .ol-scale-step-text");
      doc.setFontSize(10);
      for (let i = 0; i < 3; i++) {
        doc.text(stepTexts[i].innerText, barX + (i * barWidth) / 2, 202, {baseline: "middle", align: "center"});
      }

      // copyright notices
      doc.setFontSize(8);
      doc.text("© Schubert & Franzke, " + new Date().getFullYear(), 5 * vh_to_mm, 210 - 10 * vh_to_mm + 1, {
        baseline: "top"
      });
      doc.text(document.getElementById("attribution").innerText, 297 - 5 * vh_to_mm, 210 - 10 * vh_to_mm + 1, {
        baseline: "top",
        align: "right"
      });

      let outputLink = doc.output("datauristring");
      return outputLink.substring(outputLink.indexOf("base64,") + "base64,".length);
    }
  }
};
</script>

<style scoped></style>
